import React from 'react';
import DashboardContainer from "../../components/DashboardContainer/DashboardContainer.jsx"
import './DashboardView.css';

export default class DashboardView extends React.Component {  

    render() {
        return (
            <div>
                <DashboardContainer></DashboardContainer>
            </div>
        )
    } 
}