import React from 'react';
import { withRouter } from "react-router-dom";
import { Table, Badge, Nav, NavItem, NavLink, FormGroup, Form, Input, Label, Button, ModalHeader, ModalBody, ModalFooter,Modal, Col, Spinner } from 'reactstrap';
import axios from 'axios';
import './DashboardContainer.css'
import greenwoodLogo from '../../assets/images/greenwood_logo.png'
import LineChart from '../LineChartContainer/LineChartContainer';
import DoughnutChart from '../DoughnutChartContainer/DoughnutChartContainer';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        accessToken: '',
        refreshToken: '',
        hasSession: false,
        currentTab: 'Portfolio',
        currentCompoundTab: 'Deposit',
        userName: '',
        userId: '',
        portfolioValue: '',
        portfolioYield: '',
        dailyValue: '',
        dailyYield: '',
        accountIds: {},
        transactions: [],
        doughnutChartData: {},
        lineChartData: {},
        dashboard: false,
        compound: true,
        hasDaiBalance:true,
        paymentMethods: {},
        buyAmount: '',
        buyButtonDisabled:false,
        buyToken: 'DAI',
        buyMethod: '',
        daiAccountId: '',
        updatingDashboard: false,
        compoundDepositAmount:'',
        compoundWithdrawAmount:'',
        processingTransaction: false,
        compoundBalance: ''

    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleCompoundTabChange = this.handleCompoundTabChange.bind(this);
    this.checkActiveTab = this.checkActiveTab.bind(this);
    this.handleBuySubmit = this.handleBuySubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCompoundDepositSubmit = this.handleCompoundDepositSubmit.bind(this)
    this.handleCompoundWithdrawSubmit = this.handleCompoundWithdrawSubmit.bind(this)
  }

    componentDidMount() {
      if ( this.props.location.state && this.props.location.state.userName &&  this.props.location.state.userId) {
        this.setState({
            userName: this.props.location.state.userName,
            userId: this.props.location.state.userId,
            hasDaiBalance: this.props.location.state.hasDaiBalance,
            portfolioValue: this.props.location.state.portfolioValue,
            accountIds: {...this.props.location.state.accountIds},
            transactions: [...this.props.location.state.transactions],
            doughnutChartData: {...this.props.location.state.doughnutChartData},
            lineChartData: {...this.props.location.state.lineChartData},
            paymentMethods: {...this.props.location.state.paymentMethods},
            daiAccountId: this.props.location.state.daiAccountId,
            accessToken: this.props.location.state.accessToken,
            refreshToken: this.props.location.state.refreshToken
        })
      }
  }

  checkActiveTab = (path) => {
    //   console.log( 'RETURN: ', path === this.state.currentTab )
    return path === this.state.currentTab;
   }

   checkActiveCompoundTab = (path) => {
    // console.log( 'RETURN: ', path === this.state.currentCompoundTab )
    return path === this.state.currentCompoundTab;
 }

  handleTabChange = async (event) => {
    const newId = event.target.id;
    const oldId = this.state.currentTab

    // console.log( `OLD TAB: ${oldId}, NEW TAB: ${newId}` )

    this.setState({
        currentTab: newId
    });

    if (newId === 'Portfolio' && oldId === 'Compound') {
        console.log( 'UPDATE DASHBOARD CASE' )
        this.setState({
            updatingDashboard: true
        })
        
        const dashboardParams = {
            accessToken: this.state.accessToken,
            refreshToken: this.state.refreshToken
        }

        console.log( 'DASHBOARD PARAMS: ', dashboardParams)
        try {
            const userData = await axios.post('http://localhost:5000/setup', dashboardParams);
            console.log( 'RETURN DATA: ', userData );
            this.setState({
                transactions: userData.data.transactions 
                , doughnutChartData: userData.data.doughnutChartData
                , lineChartData: userData.data.lineChartData
                , updatingDashboard: false
            })
        } catch ( err ) {
            console.log( `Error updating dashboard - ${err.message}` )
        }

    } else if ( newId === 'Compound' && oldId === 'Portfolio' ) {
        console.log( 'UPDATE COMPOUND BALANCE CASE' )
        // get compound balance
        // if balance != 0, show it
    }

  }

  handleCompoundTabChange(event) {
      const newId = event.target.id;
      this.setState({
        currentCompoundTab: newId
    });
  }

  handleChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
    [name]: value
    });
}

  async handleBuySubmit(e) {
    e.preventDefault();
    if (this.state.buyButtonDisabled) {
        return;
    }

    this.setState({buyButtonDisabled: true});

    if (this.state.buyAmount === '') {
        console.log( 'Buy amount cannot be empty' )
        // this.setState({ showEmailError: true })
        this.setState({ buyButtonDisabled: false })
        return;
    }

    if (this.state.buyMethod === '') {
        console.log( 'Buy method cannot be empty' )
        // this.setState({ showEmailError: true })
        this.setState({ buyButtonDisabled: false })
        return;
    }

    const buyData = {
        amount: this.state.buyAmount,
        paymentMethod: this.state.buyMethod,
        daiAccount: this.state.daiAccountId
    }

    try {
        console.log( 'BUY DATA: ', buyData );
        // let result = await axios.post('https://greenwood.app/buy', buyData);
        let result = await axios.post('http://localhost:5000/buy', buyData);
        if (result.status === 200) {
            console.log('BUY RESPONSE:, ', result.data);
            this.setState({hasDaiBalance: true})
            // this.props.history.push('/discover')
            // this.props.history.push({
            //     pathname: '/discover',
            //     state_data: [{"search_id": result.data.search_id}]
            //   })
            // this.setState({ formSubmitted: true })
        }
    }
    catch (err) {
        console.log(err)
        this.setState({ showErrorAlert: true })
        this.setState({ disabled: false })
        this.setState({processingTransaction: false})
        document.getElementById("buy-dai-form").reset();
    }
}

    async handleCompoundDepositSubmit(e) {
        e.preventDefault();
        if (this.state.buyButtonDisabled) {
            return;
        }

        this.setState({buyButtonDisabled: true});

        if (this.state.compoundDepositAmount === '') {
            console.log( 'Deposit amount cannot be empty' )
            // this.setState({ showEmailError: true })
            this.setState({ buyButtonDisabled: false })
            return;
        }

        this.setState({processingTransaction: true})

        const depositData = {
            amount: this.state.compoundDepositAmount,
            coinbaseId: this.state.userId
        }

        try {
            // console.log( 'COMPOUND DEPOSIT DATA: ', depositData );
            // let result = await axios.post('https://greenwood.app/buy', buyData);
            let result = await axios.post('http://localhost:5000/deposit', depositData);
            if (result.status === 200) {
                console.log('DEPOSIT RESPONSE:, ', result.data);
                this.setState({processingTransaction: false})
                this.setState({compoundBalance: result.data.compoundBalance})
                // this.setState({hasDaiBalance: true})
                // this.props.history.push('/discover')
                // this.props.history.push({
                //     pathname: '/discover',
                //     state_data: [{"search_id": result.data.search_id}]
                //   })
                // this.setState({ formSubmitted: true })
                document.getElementById("compound-deposit-form").reset();
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ disabled: false })
            this.setState({processingTransaction: false})
            document.getElementById("compound-deposit-form").reset();
        }
    }


    async handleCompoundWithdrawSubmit(e) {
        e.preventDefault();
        if (this.state.buyButtonDisabled) {
            return;
        }

        this.setState({buyButtonDisabled: true});

        if (this.state.compoundWithdrawAmount === '') {
            console.log( 'Withdraw amount cannot be empty' )
            // this.setState({ showEmailError: true })
            this.setState({ buyButtonDisabled: false })
            return;
        }

        this.setState({processingTransaction: true})

        const withdrawData = {
            amount: this.state.compoundWithdrawAmount,
            coinbaseId: this.state.userId
        }

        try {
            // console.log( 'COMPOUND DEPOSIT DATA: ', depositData );
            // let result = await axios.post('https://greenwood.app/buy', buyData);
            let result = await axios.post('http://localhost:5000/withdraw', withdrawData);
            if (result.status === 200) {
                console.log('WITHDRAW RESPONSE:, ', result.data);
                this.setState({processingTransaction: false})
                document.getElementById("compound-deposit-form").reset();
                this.setState({compoundBalance: result.data.compoundBalance})
                // this.setState({hasDaiBalance: true})
                // this.props.history.push('/discover')
                // this.props.history.push({
                //     pathname: '/discover',
                //     state_data: [{"search_id": result.data.search_id}]
                //   })
                // this.setState({ formSubmitted: true })
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ disabled: false })
            this.setState({processingTransaction: false})
            document.getElementById("compound-deposit-form").reset();
        }
    }

    render() {
        // console.log( this.state )
    return (
        <div>
          { this.state.updatingDashboard === false ? <div className="admin">
            {/* <header className="admin__header">
                <a href="/" className="logo">
                <img src={greenwoodLogo} alt="Greenwood" width="180" height="28"/>
                </a>
                <div className="toolbar">
                    <button className="btn btn--primary">Add New Plumbus</button>
                    <h4 className="admin-header">{this.state.currentTab}</h4>
                    <a href="/logout" className="logout">
                        Log Out
                    </a>
                </div>
            </header> */}
            {this.state.currentTab && this.state.currentTab != '' ? 
            <nav className="admin__nav">
            <a href="/" className="logo">
              <img src={greenwoodLogo} alt="Greenwood" width="180" height="28"/>
            </a>
            <ul>
            <p className="logged-in-as"style={{marginBottom:"17%", color:"#FFFFFF"}}>{this.state.userName}</p>

            
            </ul>
            
            <ul className="menu">
            <li className="menu__item">
                {/* <a className="menu__link" id="Portfolio" onClick={this.handleTabChange}>Portfolio</a> */}
                <a className={`menu__link ${ this.checkActiveTab('Portfolio') ? 'active' : '' }`} id="Portfolio" href="#" onClick={this.handleTabChange}>Portfolio</a>
                
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashboard/news">News<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashboard/community">Community<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            <li className="menu__item">
                <a className={`menu__link ${ this.checkActiveTab('Compound') ? 'active' : '' }`} id="Compound" href="#" onClick={this.handleTabChange}>Compound</a>
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashbaord/maker">Maker<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashbaord/set">Set<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashbaord/uniswap">Uniswap<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            <li className="menu__item inactive-link">
                <a className="menu__link" href="/dashbaord/dydx">Dy/dx<Badge color="light" className="pull-right">Coming soon</Badge></a>
            </li>
            </ul>
        </nav>
            
            : null}
            

            {this.state.currentTab === 'Portfolio' &&  <main className="admin__main">
                <div style={{height: "55px"}}>
                  <h4 className="admin-header" style={{verticalAlign: "middle"}}>{this.state.currentTab}</h4>
                </div>
                <div className="dashboard">
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">Portfolio value</h5>
                        <div className="card dashboard-header-card">
                        ${this.state.portfolioValue ? this.state.portfolioValue : '--' }
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">Portfolio yield</h5>
                        <div className="card dashboard-header-card">
                        {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">24 hr change</h5>
                        <div className="card dashboard-header-card">
                        {this.state.dailyValue ? this.state.dailyValue : '--'}
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">24 hr yield</h5>
                        <div className="card dashboard-header-card">
                        {this.state.dailyYield ? this.state.dailyYield : '--'}
                        </div>
                    </div>


                    <div className="dashboard__item--chart">
                        <h5 className="dashboard-item-header">Portfolio value</h5>
                        <div className="card gw-card-dark-flat">
                        { this.state.lineChartData ? <LineChart data={this.state.lineChartData}/> : null }
                        </div>
                    </div>
                    <div className="dashboard__item--chart">
                        <h5 className="dashboard-item-header">Portfolio composition</h5>
                        <div className="card gw-card-dark-flat ">
                        { this.state.doughnutChartData.labels && this.state.doughnutChartData.datasets ? <DoughnutChart data={this.state.doughnutChartData}/> : null }
                        </div>
                    </div>
                    {/* <div className="dashboard__item--full"></div> */}
                    <div className="dashboard__item dashboard__item--full">
                      <h5 className="dashboard-item-header">Portfolio transaction history</h5>
                        <div className="card gw-card-dark-flat">
                            <Table size="sm" className="greenwood-table" hover>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Size</th>
                                        <th>Token</th>
                                        <th>Native amount</th>
                                        <th>Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody> {this.state.transactions.map(function(item, key) {
                                    return (
                                        <tr key = {key}>
                                            <td>{item.type}</td>
                                            <td>{item.status}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.currency}</td>
                                            <td>{item.nativeAmount}</td>
                                            <td>{item.timestamp}</td>
                                        </tr>
                                    )
                                })}</tbody>
                            </Table>
                        </div>
                    </div>
                    {/* <div className="dashboard__item dashboard__item--col">
                        <div className="card">A</div>
                    </div>
                    <div className="dashboard__item dashboard__item--col">
                        <div className="card">B</div>
                    </div>
                    <div className="dashboard__item dashboard__item--col">
                        <div className="card">C</div>
                    </div>
                    <div className="dashboard__item dashboard__item--col">
                        <div className="card">D</div>
                    </div> */}
                </div>
                {/* <p>read more about this in <a href="https://mxb.at/blog/css-grid-admin-dashboard/">this blogpost</a>.</p> */}
            </main> }

            {this.state.currentTab === 'Compound' && <main className="admin__main">
                {this.state.currentTab === 'Compound' && this.state.hasDaiBalance === false && <div className="overlay">
                    <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%"}}>
                        <Form id="buy-dai-form">
                        <h3 className="graphik-bold" style={{textAlign: "center", color: "white", marginTop:"2%"}}>Buy DAI to use Compound!</h3>
                            <FormGroup>
                                {/* <Label for="depositAmount" style={{float:"left"}}>Purchase amount</Label> */}
                                <Input type="number" name="buyAmount" id="depositAmount" placeholder="0" className="deposit-input" value={this.state.buyAmount} onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                {/* <Label for="depositToken">Token</Label> */}
                                <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/>
                            </FormGroup>

                            <FormGroup>
                                {/* <Label for="depositToken">Deposit token</Label> */}
                                <Input type="select" name="buyMethod" id="buyMethod" className="purchase-account-select" value={this.state.buyMethod} onChange={this.handleChange}>
                                    <option value='' disabled defaultValue>Select an account</option>
                                    {this.state.paymentMethods.buyMethods && this.state.paymentMethods.buyMethods.map(function(item, key) {
                                    return (
                                        <option value={item.id}>{item.methodName}</option>
                                    )
                                })}
                                </Input>
                            </FormGroup>
                            <FormGroup style={{textAlign: "center"}}>
                                {/* <Label for="depositToken">Deposit token</Label> */}
                                <Button className="compound-form-button lift"  onClick={this.handleBuySubmit} >Buy</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div> }
                <div style={{height: "55px", }}>
                  <h4 className="admin-header">{this.state.currentTab}</h4>
                </div>
                <div className="dashboard">
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">Compound balance</h5>
                        <div className="card dashboard-header-card">
                        {this.state.compoundValue ? this.state.portfolioValue : '--' }
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">Compound yield</h5>
                        <div className="card dashboard-header-card">
                        {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">24 hr change</h5>
                        <div className="card dashboard-header-card">
                        {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                        </div>
                    </div>
                    <div className="dashboard__item">
                        <h5 className="dashboard-item-header">24 hr yield</h5>
                        <div className="card dashboard-header-card">
                        {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                        </div>
                    </div>
                    <br/>
                    <div className="dashboard__item--full">
                    <h5 className="dashboard-item-header">Manage</h5>
                        <div className="card form-card" style={{background:"transparent !important"}}>
                        <Nav tabs>
                            <NavItem className="nav-item-50-percent">
                                <NavLink className={`${ this.checkActiveCompoundTab('Deposit') ? 'active deposit-tab' : 'deposit-tab' }`} href="#" id="Deposit" onClick={this.handleCompoundTabChange}>Deposit</NavLink>
                            </NavItem>
                            <NavItem className="nav-item-50-percent">
                                <NavLink className={`${ this.checkActiveCompoundTab('Withdraw') ? 'active withdraw-tab' : 'withdraw-tab' }`} href="#" id="Withdraw" onClick={this.handleCompoundTabChange}>Withdraw</NavLink>
                            </NavItem>
                        </Nav>

                        <Form id="compound-deposit-form">
                            <FormGroup style={{marginBottom: "0"}}>
                                {/* <Label for="depositAmount">Deposit amount</Label> */}
                                {this.state.processingTransaction && <div className="Aligner deposit-loader">
                                    <div className="Aligner-item">
                                    <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
                                    </div>
                                </div> }
                                { this.checkActiveCompoundTab('Deposit') && !this.state.processingTransaction && <Input type="number" name="compoundDepositAmount" id="compoundDepositAmount" placeholder="-" className="deposit-input" value={this.state.compoundDepositAmount} onChange={this.handleChange}/>}
                                { this.checkActiveCompoundTab('Withdraw') && !this.state.processingTransaction && <Input type="number" name="compoundWithdrawAmount" id="compoundWithdrawAmount" placeholder="-" className="deposit-input" value={this.state.compoundWithdrawAmount} onChange={this.handleChange}/>}
                            </FormGroup>
                            <FormGroup>
                                {/* <Label for="depositToken">Deposit token</Label> */}
                                <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="compound-deposit-token-input" disabled/>
                            </FormGroup>
                            {/* <div style={{textAlign: "center"}}>
                                <Button className="compound-form-button lift">Submit</Button>
                            </div> */}
                            <FormGroup style={{textAlign: "center"}}>
                                {/* <Label for="depositToken">Deposit token</Label> */}
                                { this.checkActiveCompoundTab('Deposit') && <Button className="compound-form-button lift" type="submit" onClick={this.handleCompoundDepositSubmit}>Submit</Button>}
                                { this.checkActiveCompoundTab('Withdraw') && <Button className="compound-form-button lift" type="submit" onClick={this.handleCompoundWithdrawSubmit}>Submit</Button>}
                            </FormGroup>
                        </Form>
                        </div>
                    </div>
                </div>
            </main> }
        
            {/* {this.state.currentTab === 'Compound' && this.state.hasDaiBalance === true ? <main className="admin__main">
                <div style={{height: "55px", }}>
                  <h4 className="admin-header">{this.state.currentTab}</h4>
                </div>
                <div className="dashboard">
                <div className="dashboard__item--full">
                        <h5 className="dashboard-item-header"></h5>
                        <div className="card no-dai-balance-card">
                        <h1 className="graphik-bold">Buy DAI with USD to start using Compound!</h1>
                        <hr/>
                        <Form id="buy-dai-form">
                            <FormGroup>
                                <Label for="depositAmount" style={{float:"left"}}>Purchase amount</Label>
                                <Input type="number" name="buyAmount" id="depositAmount" placeholder="0" className="deposit-input" value={this.state.buyAmount} onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="depositToken">Token</Label>
                                <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="depositToken">Deposit token</Label>
                                <Input type="select" name="buyMethod" id="buyMethod" className="purchase-account-select" value={this.state.buyMethod} onChange={this.handleChange}>
                                    <option value='' disabled defaultValue>Select an account</option>
                                    {this.state.paymentMethods.buyMethods && this.state.paymentMethods.buyMethods.map(function(item, key) {
                                    return (
                                        <option value={item.id}>{item.methodName}</option>
                                    )
                                })}
                                </Input>
                            </FormGroup>
                            <div style={{textAlign: "center"}}>
                                <Button className="compound-form-button lift">Submit</Button>
                            </div>
                            <FormGroup style={{textAlign: "center"}}>
                                <Label for="depositToken">Deposit token</Label>
                                <Button className="compound-form-button lift"  onClick={this.handleBuySubmit} >Submit</Button>
                            </FormGroup>
                        </Form>

                        </div>
                    </div>
                </div>
                </main> : null} */}
            </div> 
            
            :
        
            <div style={{height: "100vh"}} className="Aligner oauth-aligner">
                <div className="Aligner-item">
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
                </div>
            </div> }
        
      </div>
    );
  }
}
export default withRouter(DashboardContainer)



{/* <Nav tabs>
    <NavItem className="">
        <NavLink className={`${ this.checkActiveCompoundTab('Deposit') ? 'active' : '' }`} href="#" id="Deposit" onClick={this.handleCompoundTabChange}>Deposit</NavLink>
    </NavItem>
    <NavItem>
        <NavLink className={`${ this.checkActiveCompoundTab('Withdraw') ? 'active' : '' }`} href="#" id="Withdraw" onClick={this.handleCompoundTabChange}>Withdraw</NavLink>
    </NavItem>
</Nav> */}


{/* <div className="dashboard__item dashboard__item--col">
    <div className="card">A</div>
</div>
<div className="dashboard__item dashboard__item--col">
    <div className="card">B</div>
</div>
<div className="dashboard__item dashboard__item--col">
    <div className="card">C</div>
</div>
<div className="dashboard__item dashboard__item--col">
    <div className="card">D</div>
</div> */}

 {/* <main className="admin__main">
        <div style={{height: "70px", }}>
            <h4 className="admin-header">{this.state.currentTab}</h4>
        </div>
        <div className="dashboard">
            <div className="dashboard__item">
                <h5 className="dashboard-item-header">Portfolio value</h5>
                <div className="card dashboard-header-card">
                ${this.state.portfolioValue ? this.state.portfolioValue : '--' }
                </div>
            </div>
            <div className="dashboard__item">
                <h5 className="dashboard-item-header">Portfolio yield</h5>
                <div className="card dashboard-header-card">
                {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                </div>
            </div>
            <div className="dashboard__item">
                <h5 className="dashboard-item-header">24 hr change</h5>
                <div className="card dashboard-header-card">
                {this.state.dailyValue ? this.state.dailyValue : '--'}
                </div>
            </div>
            <div className="dashboard__item">
                <h5 className="dashboard-item-header">24 hr % change</h5>
                <div className="card dashboard-header-card">
                {this.state.dailyYield ? this.state.dailyYield : '--'}
                </div>
            </div>


            <div className="dashboard__item--chart">
                <h5 className="dashboard-item-header">Portfolio value</h5>
                <div className="card gw-card-dark-flat">
                { this.state.lineChartData ? <LineChart data={this.state.lineChartData}/> : null }
                </div>
            </div>
            <div className="dashboard__item--chart">
                <h5 className="dashboard-item-header">Portfolio composition</h5>
                <div className="card gw-card-dark-flat ">
                { this.state.doughnutChartData.labels && this.state.doughnutChartData.datasets ? <DoughnutChart data={this.state.doughnutChartData}/> : null }
                </div>
            </div>

            <div className="dashboard__item dashboard__item--full">
                <h5 className="dashboard-item-header">Portfolio transaction history</h5>
                <div className="card gw-card-dark-flat">
                    <Table size="sm" className="greenwood-table" hover>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Size</th>
                                <th>Token</th>
                                <th>Native amount</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody> {this.state.transactions.map(function(item, key) {
                            return (
                                <tr key = {key}>
                                    <td>{item.type}</td>
                                    <td>{item.status}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.currency}</td>
                                    <td>{item.nativeAmount}</td>
                                    <td>{item.timestamp}</td>
                                </tr>
                            )
                        })}</tbody>
                    </Table>
                </div>
            </div>
        </div>
    </main> */}
    {/* <footer className="admin__footer">
        <ul className="ticker">
        <li className="ticker__item">BTC: +3.12%</li>
        <li className="ticker__item">ETH: +1.29%</li>
        <li className="ticker__item">XRP: -0.32%</li>
        <li className="ticker__item">BCH: -2.82%</li>
        <li className="ticker__item">EOS: +1.44%</li>
        <li className="ticker__item">CSS: +13.37%</li>
        </ul>
        <span>
        &copy; 2019 Greenwood Inc.
        </span>
    </footer> */}