import React from 'react';
import DemoDashboardContainer from "../../components/DemoDashboardContainer/DemoDashboardContainer"
import MobileContainerDesktop from "../../components/MobileContainerDesktop/MobileContainerDesktop.jsx"
// import SwapDashboardContainer from "../../components/SwapDashboardContainer/SwapDashboardContainer.jsx"
import './DemoDashboardView.css';

export default class DemoDashboardView extends React.Component {  
    constructor(props) {
        super(props);

        this.state ={
            isDesktop: false
        }

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
      }
    
      updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 1450 });
      }

    render() {
        const isDesktop = this.state.isDesktop;
        return (
            <div>
            {isDesktop ?
                <div>
                    <DemoDashboardContainer></DemoDashboardContainer>
                    {/* <SwapDashboardContainer></SwapDashboardContainer> */}
                </div>
                :
                <div>
                    <MobileContainerDesktop/>
                </div>
            }

            </div>
        )
    } 
}