import React from 'react';
import { Navbar, Section, Container, Heading, Form, Button } from 'react-bulma-components';
import { Spinner } from 'reactstrap'
import { withRouter } from "react-router-dom";
import greenwoodLogo from '../../assets/images/greenwood_logo.png'
import axios from 'axios';
import './LoginContainer.css'



class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: '',
        password: '',
        disabled: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
        [name]: value
        });
    }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.disabled) {
        return;
    }

    this.setState({disabled: true});

    // if (this.state.email === '' || this.state.password === '') {
    //     this.setState({ showInputError: true })
    //     this.setState({ disabled: false })
    //     return;
    // }

    try {
        // let result = await axios.post('https://greatweekend-api.herokuapp.com/addLead', this.state);
        let result = await axios.post('http://localhost:5000/login', this.state);
        if (result.status === 200) {
            console.log('SERVER RESPONSE:, ', result.data);
            // this.setState({toDiscover: true})
            // this.props.history.push('/')
            this.props.history.push({
                pathname: '/dashboard',
                // state_data: [{"search_id": result.data.search_id}]
              })
            this.setState({ formSubmitted: true })
        } else {
            
        }
    }
    catch (err) {
        console.log(err)
        // this.setState({ showErrorAlert: true })
        // this.setState({ disabled: false })
        document.getElementById("info-form").reset();
    }
}

    render() {
    const { email, password } = this.state;
    return (
      <div>
          <div className="login-background" style={{height: "100vh"}}>
            <div className="container">
                <Navbar
                style={{background: "transparent"}}
                >
                    <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                        <Navbar.Item renderAs="a" href="/">
                        <img
                            src={greenwoodLogo}
                            alt="Greenwood"
                            width="180"
                            height="28"
                        />
                        </Navbar.Item>
                        <Navbar.Burger
                        active={this.state.isOpen}
                        onClick={this.toggle}
                        />
                    </Navbar.Brand>
                    <Navbar.Menu active={this.state.isOpen}>
                        <Navbar.Container position="end">
                        <Navbar.Item href="#">Login</Navbar.Item>
                        </Navbar.Container>
                    </Navbar.Menu>
                </Navbar>
              </div>
              <Section>
                <Container>
                  <Heading className="auth-header-text">Login</Heading>
                  {/* <Heading subtitle className="header-subtitle roboto-regular">Join the Greenwood waitlist on our <span className="color-orange"><a href="/" className=" demo-anchor color-orange">landing page</a></span>.</Heading> */}
                    
                    <form onSubmit={this.handleSubmit} id="info-form">
                        <div className="container" style={{maxWidth: "40%"}}>
                            <Form.Field className="auth-field">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control>
                                <Form.Input onChange={this.handleChange} name="email" type="email" placeholder="" value={email} />
                                <Form.Help>This is a help text</Form.Help>
                                </Form.Control>
                            </Form.Field>
                            <Form.Field className="auth-field">
                                <Form.Label>Password</Form.Label>
                                <Form.Control>
                                <Form.Input onChange={this.handleChange} name="password" type="password" placeholder="" value={password} />
                                <Form.Help>This is a help text</Form.Help>
                                </Form.Control>
                            </Form.Field>
                            <div style={{textAlign: 'center'}}>
                                <Button className="access-button roboto-regular" type="submit" disabled={this.state.disabled} style={{width: "80%"}}>{this.state.disabled ? <Spinner size="sm" color="light"/> : 'Submit'}</Button>
                                <p>Not a member? Sign up here.</p>
                            </div>
                        </div>
                    </form>
                </Container>
              </Section>

        </div>
      </div>
    );
  }
}
export default withRouter(LoginContainer)