import React from 'react';
import DemoContainer from "../../components/DemoContainer/DemoContainer.jsx"
import './DemoView.css';

export default class DemoView extends React.Component {  

    render() {
        return (
            <div>
                <DemoContainer></DemoContainer>
            </div>
        )
    } 
}