import React from 'react';
import { Button, Container, Heading, Section, Navbar, Columns } from 'react-bulma-components';

import greenwoodLogo from '../../assets/images/greenwood_logo_dark.png'
import './DemoVideoContainer.css';



export default class DemoVideoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        // will the landing page ever need state tho?
    };
  }

  componentDidMount() {
    //   console.log( 'CONFIG: ', config )
  }

  render() {
    return (
      <div>
        <div className="header-background-stag">
            <div className="container">
                <Navbar
                style={{background: "transparent", paddingLeft:"0px"}}
                >
                    <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                        <Navbar.Item renderAs="a" href="/" style={{paddingLeft: '0'}}>
                        <img
                            src={greenwoodLogo}
                            alt="Greenwood"
                            width="180"
                            height="28"
                        />
                        </Navbar.Item>
                        <Navbar.Burger
                        active={this.state.isOpen}
                        onClick={this.toggle}
                        />
                    </Navbar.Brand>
                    <Navbar.Menu active={this.state.isOpen}>
                        <Navbar.Container position="end">
                        {/* <Navbar.Item href="/login" className="landing-navbar-item">
                          <Button className="landing-navbar-button-empty">Login</Button>
                        </Navbar.Item> */}
                          {/* <Navbar.Item href="/register"  className="landing-navbar-item">
                            <Button className="landing-navbar-button-filled">Sign up</Button>
                          </Navbar.Item> */}
                        </Navbar.Container>
                    </Navbar.Menu>
                </Navbar>
              </div>
              <Section style={{paddingBottom: "1rem", paddingTop: "1rem"}}>
                <Container>
                  {/* <Heading className="header-text">Access capital markets<br/> <span className="color-orange">from anywhere in the world</span></Heading> */}
                  <Heading className="header-text-light">Demo video<span className="color-orange"></span></Heading>
                  {/* <Heading subtitle className="header-subtitle roboto-regular" style={{}}>Greenwood is the easiest way to create, discover, and trade financial contracts</Heading> */}

                    <iframe width="700" height="395" src="https://www.youtube.com/embed/KKk2n7KH3fU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br/>
                  {/* <Button className="access-button roboto-regular" onClick={this.open}>Get started</Button> */}
                  <a href='/alpha/15aC14d2b00A14405C4b6f2934D230E958Bb085A'><Button className="access-button roboto-regular" href="" style={{marginTop: "1.5rem"}}>Go to dashboard</Button></a>
                </Container>
              </Section>
        </div>
      </div>
    );
  }
}