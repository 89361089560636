import React from 'react';
import PrivacyContainer from "../../components/PrivacyContainer/PrivacyContainer.jsx"
import './PrivacyView.css';

export default class PrivacyView extends React.Component {  

    render() {
        return (
            <div>
                <PrivacyContainer></PrivacyContainer>
            </div>
        )
    } 
}