import React from 'react';
import {
  Row,
  Col,
  Form
 } from 'reactstrap';
 import './MobileContainerDesktop.css'
//  const bgImg = require('../../assets/images/sammich.png');


export default class MobileContainerDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

    // async componentDidMount() {

    // }

  render() {
    return (
        <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"100vh", padding:'0%', margin:"0"}}>
            <Form id="buy-dai-form" style={{width: "100%"}}>
                <div style={{textAlign: "center"}}>
                    <h3 className="graphik-bold color-orange" style={{fontSize:'0.7em', marginBottom:"2%"}} >Please visit your<br/>Greenwood Terminal<br/>from a desktop</h3>
                </div>
            </Form>
        </div>
    );
  }
}