import React from 'react';
// import SynthContainer from "../../components/SynthContainer/SynthContainer.jsx"
import ContractsContainer from "../../components/ContractsContainer/ContractsContainer.jsx"
import './SynthLandingView.css';

export default class SynthLandingView extends React.Component {  

    render() {
        return (
            <div>
                {/* <SynthContainer></SynthContainer> */}
                <ContractsContainer></ContractsContainer>
            </div>
        )
    } 
}