import React from 'react';
import RegistrationContainer from "../../components/RegistrationContainer/RegistrationContainer.jsx"
import './RegistrationView.css';

export default class RegistrationView extends React.Component {  

    render() {
        return (
            <div>
                <RegistrationContainer></RegistrationContainer>
            </div>
        )
    } 
}