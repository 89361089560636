import React from 'react';
import { Button, Navbar, Container, Heading, Section, Columns} from 'react-bulma-components';
import { Form, FormGroup, Input, Spinner, Label, FormText, InputGroup, InputGroupAddon, InputGroupText, Col} from 'reactstrap';
import greenwoodLogo from '../../assets/images/greenwood_logo_dark.png'
import './ContractsContainer.css'


export default class ContractsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      show: false
    };

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }


  open = () => this.setState({ show: true });
  close = () => this.setState({ show: false });

  render() {
    return (
      <div style={{height: "100vh"}}>
        <div className="header-background">
          <div className="container">
            <Navbar style={{background: "transparent", paddingLeft:"0px"}}>
              <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                <Navbar.Item renderAs="a" href="/" style={{paddingLeft:"0px"}}>
                  <img className="logo-image" src={greenwoodLogo} alt="Greenwood"/>
                </Navbar.Item>
              </Navbar.Brand>
            </Navbar>
          </div>
          <Section size="medium" style={{paddingBottom: "1rem", paddingTop: "1rem"}} className="main-section-contract">
            <Container style={{textAlign: "center"}}>
              <Heading className="header-text-contract">An automated interest rate swap protocol on Ethereum</Heading>
              <Heading subtitle className="header-subtitle-contract roboto-regular">Greenwood interest rate swaps allow cryptocurrency lenders to turn their floating APY loans into fixed APY loans (and vice versa) in order to hedge risk or speculate on crypto-native interest rates</Heading>
              <Columns style={{marginLeft:"0", marginRight:"0"}} className="benefit-container-contract">
                <Columns.Column className="feature-pod">
                  <Heading className="color-dark-blue benefit-title-contract">Greenwood is permissionless</Heading>
                  <Heading subtitle size={6} className="color-light-grey benefit-subtitle-contract">Anyone, anywhere in the world, can use Greenwood. All you need is an internet connection and internet money</Heading>
                </Columns.Column>
                <Columns.Column className="feature-pod">
                  <Heading className="color-dark-blue benefit-title-contract">Greenwood is non-custodial</Heading>
                  <Heading subtitle size={6} className="color-light-grey benefit-subtitle-contract">We don't custody or transmit funds for our users, so crypto lenders don't have to worry about losing control of their assets</Heading>
                </Columns.Column>
                <Columns.Column className="feature-pod">
                  <Heading className="color-dark-blue benefit-title-contract">Greenwood is autonomous</Heading>
                  <Heading subtitle size={6} className="color-light-grey benefit-subtitle-contract">Our automated market maker offers increased liquidity and improved price discovery compared to bilateral contracts</Heading>
                </Columns.Column>
              </Columns>
              {/* <a href="mailto:bmcfarland@greenwood.app?subject=Howdy%20Greenwood%20Team!"><Button className="access-button-contract roboto-regular" href="" style={{textAlign: "center"}}>Get in touch!</Button></a> */}
            </Container>
          </Section>
          <Section size="medium" className="footer-contract">
            <Container>
              <Columns breakpoint="mobile">
                <Columns.Column style={{textAlign: "center"}}>
                  <p className="color-dark-blue footer-text-contract">&copy; 2020 Greenwood. All rights reserved.</p>
                  <p>Digital currency is not legal tender, is not backed by the government. Greenwood is not a bank or a brokerage and does not offer fiduciary services. The interest rates reflected on this page are as of the current date and time, and may change at any time.</p>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
        </div>
        {/* <div>
          <Section size="medium" className="footer-contract">
            <Container>
              <Columns breakpoint="mobile">
                <Columns.Column style={{textAlign: "center"}}>
                  <p className="color-dark-blue footer-text-contract">&copy; 2020 Greenwood. All rights reserved.</p>
                  <p>Digital currency is not legal tender, is not backed by the government. Greenwood is not a bank or a brokerage and does not offer fiduciary services. The interest rates reflected on this page are as of the current date and time, and may change at any time.</p>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
        </div> */}
      </div>
    );
  }
}