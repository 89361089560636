import React from 'react';
import SynthDashboardContainer from "../../components/SynthDashboardContainer/SynthDashboardContainer.jsx"
import MobileContainer from "../../components/MobileContainer/MobileContainer.jsx"
import './SynthDashboardView.css';

export default class SynthDashboardView extends React.Component {  

    constructor(props) {
        super(props);

        this.state ={
            isDesktop: false
        }

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
      }
    
      updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 1300 });
      }

    render() {
        const isDesktop = this.state.isDesktop;
        return (
            <div>
            {isDesktop ?
                <div>
                    <SynthDashboardContainer></SynthDashboardContainer>
                </div>
                :
                <div>
                    <MobileContainer/>
                </div>
            }

            </div>
        )
    } 
}