import React from 'react';
import { Navbar, Section, Container, Heading } from 'react-bulma-components';
import greenwoodLogo from '../../assets/images/greenwood_logo_white.png'
import forwardSpec from '../../assets/images/contract_guide_forward.png'
import './DocsFuturesContainer.css'



export default class DocsFuturesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <div>
          <div className="container">
                <Navbar
                style={{background: "transparent"}}
                >
                    <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                        <Navbar.Item renderAs="a" href="/">
                        <img
                            src={greenwoodLogo}
                            alt="Greenwood"
                            width="180"
                            height="28"
                        />
                        </Navbar.Item>
                        {/* <Navbar.Burger
                        active={this.state.isOpen}
                        onClick={this.toggle}
                        /> */}
                    </Navbar.Brand>
                    <Navbar.Menu active={this.state.isOpen}>
                    </Navbar.Menu>
                </Navbar>
            </div>
            <div className="container">

            <Section size="medium" style={{paddingTop: "0px"}}>
                <Container style={{padding: "0px"}}>
                    <div className="color-light-grey content">
                    <img src={forwardSpec} alt="Greenwood Forward Contract Terms" width="100%" style={{border: "2px solid #66676F"}}/>
                    </div>
                </Container>
            </Section>
            </div>
      </div>
    );
  }
}