import React from 'react';
import TermsContainer from "../../components/TermsContainer/TermsContainer.jsx"
import './TermsView.css';

export default class TermsView extends React.Component {  

    render() {
        return (
            <div>
                <TermsContainer></TermsContainer>
            </div>
        )
    } 
}