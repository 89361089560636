import React from 'react';
import { Navbar, Section, Container, Heading, Form, Button } from 'react-bulma-components';
import greenwoodLogo from '../../assets/images/greenwood_logo.png'
import './RegistrationContainer.css'
import axios from 'axios';
import { Spinner } from 'reactstrap'
import { withRouter } from "react-router-dom";



class RegistrationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        termsAccepted: false,
        disabled: true,
        submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

async handleCheck () {
  await this.setState({termsAccepted: !this.state.termsAccepted});
  if ( this.state.termsAccepted === false ) {
    this.setState({ disabled: true })
  } else if ( this.state.termsAccepted === true ) {
    this.setState({ disabled: false })
  }
}

handleChange(e) {
  let target = e.target;
  let value = target.type === 'checkbox' ? target.checked : target.value;
  let name = target.name;

  this.setState({
  [name]: value
  });
}

async handleSubmit(e) {
e.preventDefault();
if (this.state.disabled) {
  return;
}



this.setState({disabled: true, submitted:true});

try {
  // let result = await axios.post('https://greatweekend-api.herokuapp.com/register', this.state);
  let result = await axios.post('http://localhost:5000/register', this.state);
  if (result.status === 200) {
      console.log('SERVER RESPONSE:, ', result.data);
      this.props.history.push({
          pathname: '/login',
          // state_data: [{"search_id": result.data.search_id}]
        })
      this.setState({ formSubmitted: true })
  } else {
      
  }
}
catch (err) {
  console.log(err)
  document.getElementById("register-form").reset();
}
}





  render() {
    const { firstName, lastName, email, phone, password, termsAccepted } = this.state;
    return (
      <div>
          <div className="login-background" style={{height: "100vh"}}>
            <div className="container">
                <Navbar
                style={{background: "transparent"}}
                >
                    <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                        <Navbar.Item renderAs="a" href="/">
                        <img
                            src={greenwoodLogo}
                            alt="Greenwood"
                            width="180"
                            height="28"
                        />
                        </Navbar.Item>
                        <Navbar.Burger
                        active={this.state.isOpen}
                        onClick={this.toggle}
                        />
                    </Navbar.Brand>
                    <Navbar.Menu active={this.state.isOpen}>
                        {/* <Navbar.Container position="end">
                        <Navbar.Item href="#">Login</Navbar.Item>
                        </Navbar.Container> */}
                    </Navbar.Menu>
                </Navbar>
              </div>
              <Section className="register-container">
                <Container>
                  <Heading className="auth-header-text">Create your account</Heading>
                  {/* <Heading subtitle className="header-subtitle roboto-regular">Join the Greenwood waitlist on our <span className="color-orange"><a href="/" className=" demo-anchor color-orange">landing page</a></span>.</Heading> */}
                <form onSubmit={this.handleSubmit} id="register-form">
                  <div className="container" style={{maxWidth: "40%"}}>
                      <div className="field-body auth-form-field">
                          <Form.Field className="">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control>
                              <Form.Input onChange={this.handleChange} name="firstName" type="text" placeholder="" value={firstName} />
                              {/* <Form.Help>This is a help text</Form.Help> */}
                              </Form.Control>
                          </Form.Field>
                          <Form.Field className="auth-form-field">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control>
                              <Form.Input onChange={this.handleChange} name="lastName" type="text" placeholder="" value={lastName} />
                              {/* <Form.Help>This is a help text</Form.Help> */}
                              </Form.Control>
                          </Form.Field>
                      </div>
                      <Form.Field className="auth-field">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control>
                          <Form.Input onChange={this.handleChange} name="email" type="email" placeholder="" value={email} />
                          {/* <Form.Help>This is a help text</Form.Help> */}
                          </Form.Control>
                      </Form.Field>
                      <Form.Field className="auth-field">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control>
                          <Form.Input onChange={this.handleChange} name="phone" type="tel" placeholder="" value={phone} />
                          {/* <Form.Help>This is a help text</Form.Help> */}
                          </Form.Control>
                      </Form.Field>
                      <Form.Field className="auth-field">
                          <Form.Label>Password</Form.Label>
                          <Form.Control>
                          <Form.Input onChange={this.handleChange} name="password" type="password" placeholder="" value={password} />
                          {/* <Form.Help>This is a help text</Form.Help> */}
                          </Form.Control>
                      </Form.Field>
                      <Form.Field >
                          <Form.Control>
                          <Form.Checkbox onChange={this.handleCheck} checked={termsAccepted} style={{color:"#8F9096"}} className="checkbox-label">
                              <span style={{marginLeft:"5px"}}>I certify that I am 18 years of age or older, and I agree to the <span><a href="/terms">Terms of Service</a></span> and <span><a href="/privacy">Privacy Policy</a></span>.</span>
                          </Form.Checkbox>
                          </Form.Control>
                      </Form.Field>
                      <div style={{textAlign: 'center'}}>
                          <Button className="access-button roboto-regular" type="submit" disabled={this.state.disabled} style={{width: "80%"}}>{this.state.disabled  && this.state.submitted ? <Spinner size="sm" color="light"/> : 'Submit'}</Button>
                          <p>Aleardy a member? Login here.</p>
                      </div>
                  </div>
                </form>
                </Container>
              </Section>
        </div>
      </div>
    );
  }
}

export default withRouter(RegistrationContainer)