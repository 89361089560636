import React from 'react';
import { withRouter } from "react-router-dom";
import { Table, Badge, Nav, NavItem, NavLink, FormGroup, Form, Input, Button, Spinner, Label, FormText, InputGroup, InputGroupAddon, InputGroupText, CustomInput, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import axios from 'axios';
import './DemoDashboardContainer.css'
import greenwoodLogo from '../../assets/images/greenwood_logo_white.png'
import LineChart from '../LineChartContainer/LineChartContainer';
import DoughnutChart from '../DoughnutChartContainer/DoughnutChartContainer';
import Web3 from 'web3';
import Fortmatic from 'fortmatic'
import config from './../../config/config'
import moment from 'moment-timezone';
// const backendPath = 'http://localhost:5001'
const backendPath = 'https://greenwood-synth-api.herokuapp.com'

class DemoDashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // accessToken: '',
            // refreshToken: '',
            hasSession: false,
            currentTab: 'Portfolio',
            currentCompoundTab: 'Deposit',
            // userName: '',
            // userId: '',
            portfolioValue: '',
            portfolioYield: '',
            dailyValue: '',
            dailyYield: '',
            // accountIds: {},
            transactions: [],
            unfilledBuyerContracts: [],
            unfilledSellerContracts: [],
            doughnutChartData: {},
            defaultDoughnutChartData: {
                	labels: [
                		'no data',
                	],
                	datasets: [
                        {
                            data: [100],
                            backgroundColor: [
                                '#66676F'
                            ],
                            hoverBackgroundColor: [
                                '#66676F'
                            ]
                        }
                    ]
                },
            // lineChartData: [65, 59, 80, 81, 56, 55, 40, 5, 45, 20, 90, 50] ,
            lineChartData: [25, 60, 42, 70, 74, , , , , , , ] ,
            // lineChartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] ,
            dashboard: false,
            compound: true,
            hasDaiBalance:false,
            paymentMethods: {},
            // buyAmount: '',
            buyButtonDisabled:false,
            // buyToken: 'DAI',
            // buyMethod: '',
            // daiAccountId: '',
            updatingDashboard: false,
            // compoundDepositAmount:'',
            // compoundWithdrawAmount:'',
            processingTransaction: false,
            // compoundBalance: '',
            authModalSignup: false,
            hasWeb3Provider: true,
            currentAccount: '0xb14A484B3D85Ff97E8d06CcA88a2d0aabE63CBb5',
            currentAccountTrunc: '0xb14A48...63CBb5',
            hasGreenwoodContracts: true,
            selectedPosition: '',
            selectedContractType: '',
            selectedUnderlyingAsset: '',
            selectedStrikePrice:'',
            selectedSize: '',
            selectedMinimumCollateralizationRatio: '',
            selectedInitialCollateralizationRatio: '',
            selectedDuration: '',
            selectedPriceProvider: '',
            initialCollateral: '',
            selectedUpdateFrequency: '',
            totalCost:'',
            priceOfDai:'',
            deployedContractAddress: '',
            showOfferDetailModal: false,
            detailModalPosition: '',
            detailModalContractType: '',
            detailModalUnderlyingAsset: '',
            detailModalPriceProvider: '',
            detailModalStrikePrice: '',
            detailModalSize: '',
            detailModalCollateralizationRatio: '',
            detailModalUpdateFrequency: '',
            detailModalExpiry: '',
            detailModalCollateralRequirement: '',
            datailModalContractAddress: '',
            isDeployingContract: false,
            contractDeployed: false,
            isFillingContract: false,
            contractFilled: false,
            filledContractTxHash: '',
            transactionHistory: [],
            currentContracts: [],
            showCurrentContractModal: false,
            currentContractModalPosition: '',
            currentContractModalContractType: '',
            currentContractModalUnderlyingAsset: '',
            currentContractModalPriceProvider: '',
            currentContractModalStrikePrice: '',
            currentContractModalSize: '',
            currentContractModalCollateralizationRatio: '',
            currentContractModalUpdateFrequency: '',
            currentContractModalExpiry: '',
            currentContractModalCollateralRequirement: '',
            isDepositingCollateral: false,
            collateralDeposited: false,
            isWithdrawingCollateral: false,
            collateralWithdrawn: false,
            collateralTxHash: '',
            currentContractModalContractAddress: '',
            isManagingCollateral: false,
            currentContractModalManageCollateralAmount: '',
            unfilledContracts: [],
            showUnfilledContractModal: false,
            isClosingContract: false,
            isContractClosed: false,
            closedTxHash: '',
            unfilledContractModalPosition: '',
            unfilledContractModalContractType: '',
            unfilledContractModalUnderlyingAsset: '',
            unfilledContractModalPriceProvider: '',
            unfilledContractModalStrikePrice: '',
            unfilledContractModalSize: '',
            unfilledContractModalCollateralizationRatio: '',
            unfilledContractModalUpdateFrequency: '',
            unfilledContractModalExpiry: '',
            unfilledContractModalCollateralRequirement: '',
            ethBalance: '',
            daiBalance: '',
            wtiPrice: 0,
            currentUnderlyingPrices: []
        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleCompoundTabChange = this.handleCompoundTabChange.bind(this);
        this.checkActiveTab = this.checkActiveTab.bind(this);
        // this.handleBuySubmit = this.handleBuySubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleCompoundDepositSubmit = this.handleCompoundDepositSubmit.bind(this)
        // this.handleCompoundWithdrawSubmit = this.handleCompoundWithdrawSubmit.bind(this)
        this.handleAuthModalChange = this.handleAuthModalChange.bind(this)
        this.handleFortmaticAuth = this.handleFortmaticAuth.bind(this);
        this.smartTrim = this.smartTrim.bind(this);
        this.handleMetamaskAuth = this.handleMetamaskAuth.bind(this);
        // this.getTransactionsByAccount = this.getTransactionsByAccount.bind(this)
        this.changeRadio = this.changeRadio.bind(this)
        this.submitContractTerms = this.submitContractTerms.bind(this)
        this.calculateInitialCollateral = this.calculateInitialCollateral.bind(this)
        this.calculateTotalCosts = this.calculateTotalCosts.bind(this)
        this.toggleOfferDetailModal = this.toggleOfferDetailModal.bind(this);
        this.fillOrder = this.fillOrder.bind(this);
        this.toggleCurrentContractModal = this.toggleCurrentContractModal.bind(this);
        this.depositCollateral = this.depositCollateral.bind(this);
        this.withdrawCollateral = this.withdrawCollateral.bind(this);
        this.manageCollateral = this.manageCollateral.bind(this);
        this.toggleUnfilledContractModal = this.toggleUnfilledContractModal.bind(this);
        this.refreshPrices = this.refreshPrices.bind(this);
    }

    async componentDidMount() {
        // console.log( 'PROVIDER: ', Web3.currentProvider )
        // console.log( 'PATH: ', this.props.location.pathname )
        if ( Web3.currentProvider ) {
            Web3.currentProvider.enable().then(console.log)
        }

    
        const formData = {
            path: this.props.location.pathname.split( '/' )[2] ? this.props.location.pathname.split( '/' )[2] : this.props.location.pathname.split( '/' )[1],
        }

        try {
            // console.log( 'MOUNT' )
            // const userData = await axios.post(`http://localhost:5001/tests/setup-dashboard`, formData);
            // const userData = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/setup-dashboard', formData);
            const userData = await axios.post(`${backendPath}/tests/setup-dashboard`, formData);
            // console.log( 'TAB CHANGE DATA FOR DASHBOARD SETUP: ', userData );
            this.setState({
                // transactions: userData.data.transactionHistory 
                transactionHistory: userData.data.transactionHistory 
                , currentContracts: userData.data.currentContracts
                , unfilledContracts: userData.data.unfilledContracts
                , doughnutChartData: userData.data.doughnutChartData.datasets[0].data.length ? userData.data.doughnutChartData : this.state.defaultDoughnutChartData
                , currentAccount: userData.data.currentAccount
                , currentAccountTrunc: userData.data.currentAccountTrunc
                , ethBalance: userData.data.ethBalance
                , daiBalance: userData.data.daiBalance
                , wtiPrice: userData.data.wtiPrice
                , currentUnderlyingPrices: userData.data.currentUnderlyingPrices
                // , lineChartData: userData.data.lineChartData
                , updatingDashboard: false
            });
        } catch ( err ) {
            console.log( `Error updating dashboard - ${err.message}` );
        }


        // this.setState({
        //     lineChartData: {...this.state.lineChartData},
        //     doughnutChartData: {...this.state.doughnutChartData}
        // })

        // const fm = new Fortmatic('pk_test_A46769E03566AC61');
        // const web3 = new Web3(fm.getProvider());
        // web3.currentProvider.enable().then(console.log)

        // if (window.ethereum) {
        //     // Use MetaMask provider
        //     window.web3 = new Web3(window.ethereum);
        //     window.web3.currentProvider.enable().then(console.log)
        //   } else {
        //     // Use Fortmatic provider
        //     window.web3 = new Web3(fm.getProvider());
        //     window.web3.currentProvider.enable().then(console.log)
        //   }

        
        // if ( this.props.location.state && this.props.location.state.userName &&  this.props.location.state.userId) {
        //     this.setState({
        //         userName: this.props.location.state.userName,
        //         userId: this.props.location.state.userId,
        //         hasDaiBalance: this.props.location.state.hasDaiBalance,
        //         portfolioValue: this.props.location.state.portfolioValue,
        //         accountIds: {...this.props.location.state.accountIds},
        //         transactions: [...this.props.location.state.transactions],
        //         doughnutChartData: {...this.props.location.state.doughnutChartData},
        //         lineChartData: {...this.props.location.state.lineChartData},
        //         paymentMethods: {...this.props.location.state.paymentMethods},
        //         daiAccountId: this.props.location.state.daiAccountId,
        //         accessToken: this.props.location.state.accessToken,
        //         refreshToken: this.props.location.state.refreshToken
        //     })
        // }

        // if (this.state.userId) {
        //     console.log( 'FETCHING COMPOUND BALANCE', this.state.userId )
        //     try {
        //         const userData = await axios.post('http://localhost:5000/compound/balance', { coinbaseId: this.state.userId });
        //         console.log( 'TAB CHANGE DATA FOR COMPOUND BALANCE: ', userData.data.compoundBalance );
        //         this.setState({
        //             compoundBalance: userData.data.compoundBalance 
        //         });
        //     } catch ( err ) {
        //         console.log( `Error getting compound balance - ${err.message}` );
        //     }
        // }


    }

    manageCollateral() {
        this.setState({
            isManagingCollateral: true
        });
    }

    toggleOfferDetailModal = async (position, contractType, underlyingAsset, priceProvider, strikePrice, size, minimumCollateralizationRatio, updateFrequency, expirationTime, collateralRequirement, contractAddress, e) => {

        try {
            // console.log( 'FETCHING UNFILLED CONTRACTS' )
            const contracts = await axios.post(`${backendPath}/tests/get-unfilled-contracts`);
            // const contracts = await axios.post('http://localhost:5001/tests/get-unfilled-contracts');
            // const contracts = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/get-unfilled-contracts');
            const contractData = contracts.data.result
            this.setState({
                unfilledBuyerContracts: contractData.unfilledBuyerContracts,
                unfilledSellerContracts: contractData.unfilledSellerContracts 
                // unfilledContracts: [] 
            });
        } catch ( err ) {
            console.log( `Error getting unfilled contracts - ${err.message}` );
        }


        this.setState({
            showOfferDetailModal: !this.state.showOfferDetailModal,
            detailModalPosition: position,
            detailModalContractType: contractType,
            detailModalUnderlyingAsset: underlyingAsset,
            detailModalPriceProvider: priceProvider,
            detailModalStrikePrice: strikePrice,
            detailModalSize: size,
            detailModalCollateralizationRatio: minimumCollateralizationRatio,
            detailModalUpdateFrequency: updateFrequency,
            detailModalExpiry: expirationTime,
            detailModalCollateralRequirement: collateralRequirement,
            detailModalContractAddress: contractAddress,
            isFillingContract: false,
            contractFilled: false,
            filledContractTxHash: ''
        });
      }

      toggleCurrentContractModal = async (position, contractType, underlyingAsset, priceProvider, strikePrice, size, minimumCollateralizationRatio, updateFrequency, expirationTime, collateralRequirement, contractAddress, e) => {

        // console.log('HERE: ', position, contractType, underlyingAsset, priceProvider, strikePrice, size, minimumCollateralizationRatio, updateFrequency, expirationTime)

        let formettedPosition;
        if ( position.toLowerCase() === 'buy' ) {
            formettedPosition = 'buyer'
        } else if ( position.toLowerCase() === 'sell' ) {
            formettedPosition = 'seller'
        }

        this.setState({
            showCurrentContractModal: !this.state.showCurrentContractModal,
            currentContractModalPosition: position,
            currentContractModalContractType: contractType,
            currentContractModalUnderlyingAsset: underlyingAsset,
            currentContractModalPriceProvider: priceProvider,
            currentContractModalStrikePrice: strikePrice,
            currentContractModalSize: size,
            currentContractModalCollateralizationRatio: minimumCollateralizationRatio,
            currentContractModalUpdateFrequency: updateFrequency,
            currentContractModalExpiry: expirationTime,
            // currentContractModalCollateralRequirement: collateralRequirement === '' ? '' : parseFloat(parseFloat(currentCollateralizationRatio.data).toFixed(2)) * 100 ,
            currentContractModalContractAddress: contractAddress,
            isDepositingCollateral: false,
            collateralDeposited: false,
            isWithdrawingCollateral: false,
            collateralWithdrawn: false,
            collateralTxHash: '',
            isManagingCollateral: false
        });

        if ( collateralRequirement !== '' ) {
            let currentCollateralizationRatio;
            try {
                const fetchData = {
                    contractAddress,
                    position: formettedPosition,
                    size
                }
                currentCollateralizationRatio = await axios.post(`${backendPath}/tests/calculate-collateralization`, fetchData);
                // currentCollateralizationRatio = await axios.post('http://localhost:5001/tests/calculate-collateralization', fetchData);
                // currentCollateralizationRatio = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/calculate-collateralization', fetchData);
                // console.log( 'WORKING HERE: ', currentCollateralizationRatio.data )

                this.setState({
                    currentContractModalCollateralRequirement: parseFloat(currentCollateralizationRatio.data * 100).toFixed(2)
                })
            } catch ( err ) {
                console.error( `Error fetching collateralization data - ${ err.message }` )
            }
        } else {
            this.setState({
                currentContractModalCollateralRequirement: ''
            })
        }

      }

      toggleUnfilledContractModal = async (position, contractType, underlyingAsset, priceProvider, strikePrice, size, minimumCollateralizationRatio, updateFrequency, expirationTime, collateralRequirement, contractAddress, e) => {

        // console.log('HERE: ', position, contractType, underlyingAsset, priceProvider, strikePrice, size, minimumCollateralizationRatio, updateFrequency, expirationTime)

        let formettedPosition;
        if ( position.toLowerCase() === 'buy' ) {
            formettedPosition = 'buyer'
        } else if ( position.toLowerCase() === 'sell' ) {
            formettedPosition = 'seller'
        }

        this.setState({
            showUnfilledContractModal: !this.state.showUnfilledContractModal,
            unfilledContractModalPosition: position,
            unfilledContractModalContractType: contractType,
            unfilledContractModalUnderlyingAsset: underlyingAsset,
            unfilledContractModalPriceProvider: priceProvider,
            unfilledContractModalStrikePrice: strikePrice,
            unfilledContractModalSize: size,
            unfilledContractModalCollateralizationRatio: minimumCollateralizationRatio,
            unfilledContractModalUpdateFrequency: updateFrequency,
            unfilledContractModalExpiry: expirationTime,
            // currentContractModalCollateralRequirement: collateralRequirement === '' ? '' : parseFloat(parseFloat(currentCollateralizationRatio.data).toFixed(2)) * 100 ,
            unfilledContractModalContractAddress: contractAddress,
            isClosingContract: false,
            isContractClosed: false,
            closedTxHash: ''
        });

        if ( collateralRequirement !== '' ) {
            let currentCollateralizationRatio;
            try {
                const fetchData = {
                    contractAddress,
                    position: formettedPosition,
                    size
                }
                currentCollateralizationRatio = await axios.post(`${backendPath}/tests/calculate-collateralization`, fetchData);
                // currentCollateralizationRatio = await axios.post('http://localhost:5001/tests/calculate-collateralization', fetchData);
                // currentCollateralizationRatio = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/calculate-collateralization', fetchData);
                // console.log( 'WORKING HERE: ', currentCollateralizationRatio.data )

                this.setState({
                    unfilledContractModalCollateralRequirement: parseFloat(currentCollateralizationRatio.data * 100).toFixed(2)
                })
            } catch ( err ) {
                console.error( `Error fetching collateralization data - ${ err.message }` )
            }
        } else {
            this.setState({
                unfilledContractModalCollateralRequirement: ''
            })
        }

      }

    fillOrder = async () => {
        this.setState({
            isFillingContract: true,
            contractFilled: false
        });

        try {
            const formData = {
                userAddress: this.state.currentAccount,
                fillerPosition: this.state.detailModalPosition,
                amountInDai: Number(this.state.detailModalCollateralRequirement),
                deployedContractAddress: this.state.detailModalContractAddress
            }

            // console.log( 'FORM DATA: ', formData )

            const contractAddress = await axios.post(`${backendPath}/tests/fill`, formData);
            // const contractAddress = await axios.post('http://localhost:5001/tests/fill', formData);
            // const contractAddress = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/fill', formData);

            // console.log( contractAddress.data )
            
            this.setState({
                filledContractTxHash: contractAddress.data,
                isFillingContract: false,
                contractFilled: true
            });
            
            // console.log( 'FILLED CONTRACT AT ADDRESS: ', contractAddress.data, );
        } catch ( err ) {
            console.log( `Error sending form data - ${ err.message }` );
            this.setState({
                isFillingContract: false,
                contractFilled: false
            });
        }

    }

    depositCollateral = async () => {
        this.setState({
            isDepositingCollateral: true,
            collateralDeposited: false
        });

        let formettedPosition;
        if ( this.state.currentContractModalPosition.toLowerCase() === 'buy' ) {
            formettedPosition = 'buyer'
        } else if ( this.state.currentContractModalPosition.toLowerCase() === 'sell' ) {
            formettedPosition = 'seller'
        }

        try {
            const formData = {
                userAddress: this.state.currentAccount,
                position: formettedPosition,
                amountInDai: Number(this.state.currentContractModalManageCollateralAmount),
                deployedContractAddress: this.state.currentContractModalContractAddress
            }

            // console.log( 'FORM DATA: ', formData )

            const contractAddress = await axios.post(`${backendPath}/tests/deposit-collateral`, formData);
            // const contractAddress = await axios.post('http://localhost:5001/tests/deposit-collateral', formData);
            // const contractAddress = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/deposit-collateral', formData);

            // console.log( contractAddress.data )
            
            this.setState({
                collateralTxHash: contractAddress.data.transactionHash,
                isDepositingCollateral: false,
                collateralDeposited: true
            });
            
            // console.log( 'COLLATERAL DEPOSIT TX HASH: ', contractAddress.data.transactionHash, );
        } catch ( err ) {
            console.log( `Error sending form data - ${ err.message }` );
            this.setState({
                isDepositingCollateral: false,
                collateralDeposited: true
            });
        }

    }

    withdrawCollateral = async () => {
        this.setState({
            isWithdrawingCollateral: true,
            collateralWithdrawn: false
        });

        let formattedPosition;
        if ( this.state.currentContractModalPosition.toLowerCase() === 'buy' ) {
            formattedPosition = 'buyer'
        } else if ( this.state.currentContractModalPosition.toLowerCase() === 'sell' ) {
            formattedPosition = 'seller'
        }

        try {
            const formData = {
                userAddress: this.state.currentAccount,
                position: formattedPosition,
                amountInDai: Number(this.state.currentContractModalManageCollateralAmount),
                deployedContractAddress: this.state.currentContractModalContractAddress
            }

            // console.log( 'FORM DATA: ', formData )

            const contractAddress = await axios.post(`${backendPath}/tests/withdraw-collateral`, formData);
            // const contractAddress = await axios.post('http://localhost:5001/tests/withdraw-collateral', formData);
            // const contractAddress = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/withdraw-collateral', formData);

            // console.log( contractAddress.data )
            
            this.setState({
                collateralTxHash: contractAddress.data.transactionHash,
                isWithdrawingCollateral: false,
                collateralWithdrawn: true
            });
            
            // console.log( 'COLLATERAL WITHDRAW TX HASH: ', contractAddress.data.transactionHash, );
        } catch ( err ) {
            console.log( `Error sending form data - ${ err.message }` );
            this.setState({
                isWithdrawingCollateral: false,
                collateralWithdrawn: true
            });
        }

    }

    submitContractTerms = async () => {
        this.setState({
            isDeployingContract: true,
            contractDeployed: false
        });

        try {
            const formData = {
                administratorAddress: '0x3d393fe82200cfc44b9004401310bC2253B839d4',
                userAddress: this.state.currentAccount,
                daiAddress: '0xC4375B7De8af5a38a93548eb8453a498222C4fF2',
                strikePrice: Number(this.state.selectedStrikePrice),
                size: Number(this.state.selectedSize)*100,
                creatorPosition: this.state.selectedPosition,
                underlyingAsset: this.state.selectedUnderlyingAsset,
                expirationTime: Number(this.state.selectedDuration),
                minimumCollateralizationRatio: Number(this.state.selectedMinimumCollateralizationRatio),
                collateralPriceInCents: Number(Number(this.state.priceOfDai).toFixed(2))*100,
                amount: Number(this.state.initialCollateral),
                updateFrequency: this.state.selectedUpdateFrequency
                // amount: 3
            }

            // console.log( 'FORM DATA: ', formData )

            const contractAddress = await axios.post(`${backendPath}/tests/deploy`, formData);
            // const contractAddress = await axios.post('http://localhost:5001/tests/deploy', formData);
            // const contractAddress = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/deploy', formData); 
            
            this.setState({
                deployedContractAddress: contractAddress.data,
                isDeployingContract: false,
                contractDeployed: true
            });
            
            // console.log( 'NEW CONTRACT DEPLOYED AT ADDRESS: ', this.state.deployedContractAddress );
        } catch ( err ) {
            console.log( `Error sending form data - ${ err.message }` );
            this.setState({
                isDeployingContract: false,
                contractDeployed: false
            });
        }
    }

    closeContract = async () => {
        this.setState({
            isClosingContract: true,
            isContractClosed: false
        });

        let formattedPosition;
        if ( this.state.unfilledContractModalPosition.toLowerCase() === 'buy' ) {
            formattedPosition = 'buyer'
        } else if ( this.state.unfilledContractModalPosition.toLowerCase() === 'sell' ) {
            formattedPosition = 'seller'
        }

        try {
            const formData = {
                userAddress: this.state.currentAccount,
                deployedContractAddress: this.state.unfilledContractModalContractAddress,
                position: formattedPosition
            }

            // console.log( 'FORM DATA: ', formData )

            const result = await axios.post(`${backendPath}/tests/issuer-close`, formData);
            // const result = await axios.post('http://localhost:5001/tests/issuer-close', formData);

            this.setState({
                closedTxHash: result.data.transactionHash,
                isClosingContract: false,
                isContractClosed: true
            });
            
            // console.log( 'CLOSE CONTRACT TX HASH: ', this.state.closedTxHash);
        } catch ( err ) {
            console.error( `Error closing contract - ${ err.message }` );
            this.setState({
                isClosingContract: false,
                isContractClosed: false
            });
        }

    }

    changeRadio(e) {
        this.setState({ selectedPosition: e.target.value});
      }

    checkActiveTab = (path) => {
        return path === this.state.currentTab;
    }

    checkActiveCompoundTab = (path) => {
        return path === this.state.currentCompoundTab;
    }

    checkWeb3Provider = () => {
        const provider = window.web3.isConnected();
        console.log( 'provider', provider )
        if ( provider ) {
            return true
        } else {
            return false
        }
    }

    handleTabChange = async (event) => {
        event.persist()
        let newId = event.target.id;
        let oldId = this.state.currentTab

        // console.log( 'TAB CHANGE EVENT: ', event )
        // console.log( 'TAB CHANGE ID: ', newId )

        if (newId.includes( '-' ) ) {
            console.log( newId )
            const splitValue = newId.split('-');
            newId = splitValue[0]
        }

        this.setState({
            currentTab: newId
        });

        if (newId === 'Portfolio' ) {
            // console.log( 'FETCH PORTFOLIO DATA' );
            this.setState({
                updatingDashboard: true
            })


            const formData = {
                path: this.props.location.pathname.split( '/' )[2] ? this.props.location.pathname.split( '/' )[2] : this.props.location.pathname.split( '/' )[1]
            }
        
            try {
                const userData = await axios.post(`${backendPath}/tests/setup-dashboard`, formData);
                // const userData = await axios.post('http://localhost:5001/tests/setup-dashboard', formData);
                // const userData = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/setup-dashboard', formData);
                // console.log( 'TAB CHANGE DATA FOR DASHBOARD SETUP: ', userData );
                this.setState({
                    // transactions: userData.data.transactionHistory 
                    transactionHistory: userData.data.transactionHistory 
                    , currentContracts: userData.data.currentContracts
                    , currentAccount: userData.data.currentAccount
                    , currentAccountTrunc: userData.data.currentAccountTrunc
                    , unfilledContracts: userData.data.unfilledContracts
                    , ethBalance: userData.data.ethBalance
                    , daiBalance: userData.data.daiBalance
                    , wtiPrice: userData.data.wtiPrice
                    , doughnutChartData: userData.data.doughnutChartData.datasets[0].data.length ? userData.data.doughnutChartData : this.state.defaultDoughnutChartData
                    // , lineChartData: userData.data.lineChartData
                    , updatingDashboard: false
                });
            } catch ( err ) {
                console.log( `Error updating dashboard - ${err.message}` );
            }

        } else if ( newId === 'Market' ) {
            // console.log( 'FETCH MARKET DATA' );
            try {
                // console.log( 'FETCHING UNFILLED CONTRACTS' )
                const contracts = await axios.post(`${backendPath}/tests/get-unfilled-contracts`);
                // const contracts = await axios.post('http://localhost:5001/tests/get-unfilled-contracts');
                // const contracts = await axios.post('https://greenwood-synth-api.herokuapp.com/tests/get-unfilled-contracts');
                const contractData = contracts.data.result
                this.setState({
                    unfilledBuyerContracts: contractData.unfilledBuyerContracts,
                    unfilledSellerContracts: contractData.unfilledSellerContracts 
                    // unfilledContracts: [] 
                });
            } catch ( err ) {
                console.log( `Error getting unfilled contracts - ${err.message}` );
            }
        } else if ( newId === 'Create' ) {
            // console.log( 'RENDER CREATE FORM' );
        }

    }

    handleCompoundTabChange(event) {
        const newId = event.target.id;
        this.setState({
            currentCompoundTab: newId
        });
    }

    handleAuthModalChange(event) {
        // const newId = event.target.id;
        this.setState({
            authModalSignup: !this.state.authModalSignup
        });
    }

    smartTrim(string, maxLength) {
        // console.log( `called st with ${string} and ${maxLength}` );
        if (!string) return string;
        if (maxLength < 1) return string;
        if (string.length <= maxLength) return string;
        if (maxLength == 1) return string.substring(0,1) + '...';
    
        var midpoint = Math.ceil(string.length / 2);
        var toremove = string.length - maxLength;
        var lstrip = Math.ceil(toremove/2);
        var rstrip = toremove - lstrip;
        return string.substring(0, midpoint-lstrip) + '...' 
        + string.substring(midpoint+rstrip);
    } 

    async handleFortmaticAuth( event ) {
        const fm = new Fortmatic('pk_test_A46769E03566AC61');
        window.web3 = new Web3(fm.getProvider());
        let result = await window.web3.currentProvider.enable();
        result = await this.smartTrim(result[0], 16);
    
        this.setState({
            hasWeb3Provider: true,
            currentAccount: result
        })

    }

    // async getTransactionsByAccount(myaccount, startBlockNumber, endBlockNumber) {
    //     if (endBlockNumber == null) {
    //       endBlockNumber = await window.web3.eth.getBlockNumber();
    //       console.log("Using endBlockNumber: " + endBlockNumber);
    //     }
    //     if (startBlockNumber == null) {
    //       startBlockNumber = endBlockNumber - 1000;
    //       console.log("Using startBlockNumber: " + startBlockNumber);
    //     }
    //     console.log("Searching for transactions to/from account \"" + myaccount + "\" within blocks "  + startBlockNumber + " and " + endBlockNumber);
      
    //     for (var i = startBlockNumber; i <= endBlockNumber; i++) {
    //       if (i % 1000 === 0) {
    //         console.log("Searching block " + i);
    //       }
    //       var block = await window.web3.eth.getBlock(i, true);
    //       console.log( 'GOT BLOCK: ', block );
    //       if (block != null && block.transactions != null) {
    //         block.transactions.forEach( function(e) {
    //           if (myaccount === "*" || myaccount === e.from || myaccount === e.to) {
    //             console.log("  tx hash          : " + e.hash + "\n"
    //               + "   nonce           : " + e.nonce + "\n"
    //               + "   blockHash       : " + e.blockHash + "\n"
    //               + "   blockNumber     : " + e.blockNumber + "\n"
    //               + "   transactionIndex: " + e.transactionIndex + "\n"
    //               + "   from            : " + e.from + "\n" 
    //               + "   to              : " + e.to + "\n"
    //               + "   value           : " + e.value + "\n"
    //               + "   time            : " + block.timestamp + " " + new Date(block.timestamp * 1000).toGMTString() + "\n"
    //               + "   gasPrice        : " + e.gasPrice + "\n"
    //               + "   gas             : " + e.gas + "\n"
    //               + "   input           : " + e.input);
    //           }
    //         })
    //       }
    //     }
    //   }


    async handleMetamaskAuth( event ) {
        let result, resultTrimmed;
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);

            result = await window.web3.currentProvider.enable();
            resultTrimmed = await this.smartTrim(result[0], 18);
            // const transactionHistory = await this.getTransactionsByAccount( result[0] )

          } else {
                alert('Metamask not detected.')
          }
    
        this.setState({
            hasWeb3Provider: true,
            currentAccount: resultTrimmed
        })

    }

    handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        
        this.setState({
            [name]: value,
        }, () => {
            this.calculateInitialCollateral()
            this.calculateTotalCosts()
        });

    }

    calculateInitialCollateral = async () => {
        if ( this.state.selectedSize !== '' && this.state.selectedStrikePrice !== '' && this.state.selectedInitialCollateralizationRatio !== '' ) {
            // console.log( this.state.selectedSize, this.state.selectedStrikePrice, this.state.selectedMinimumCollateralizationRatio)
            const initialCollateralInDollars = Number(this.state.selectedSize) * Number(this.state.selectedStrikePrice) * (Number(this.state.selectedInitialCollateralizationRatio)/100)
            
            let priceOfDai;
            try {
                const response = await axios.get( 'https://api.coinbase.com/v2/prices/DAI-USD/spot' );
                priceOfDai = response.data.data.amount
            } catch ( err ) {
                console.error ( `Error fetcing price of DAI from Coinbase - ${ err.message }` )
            }

            if ( priceOfDai ) {
                const initialCollateralInDai = initialCollateralInDollars / priceOfDai
                this.setState({
                    initialCollateral: Math.ceil(initialCollateralInDai),
                    priceOfDai: priceOfDai
                })
            }
        }
    }

    calculateTotalCosts = async () => {
        // console.log('CALLED')
        if ( this.state.selectedUpdateFrequency !== '' && this.state.selectedDuration !== '' ) {
            // console.log( this.state.selectedSize, this.state.selectedStrikePrice, this.state.selectedMinimumCollateralizationRatio)
            const initialCollateralInDollars = Number(this.state.selectedSize) * Number(this.state.selectedStrikePrice) * (Number(this.state.selectedInitialCollateralizationRatio)/100)

            let priceOfEth;
            try {
                const response = await axios.get( 'https://api.coinbase.com/v2/prices/ETH-USD/spot' );
                priceOfEth = response.data.data.amount
            } catch ( err ) {
                console.error ( `Error fetcing price of ETH from Coinbase - ${ err.message }` )
            }

            if ( priceOfEth ) {
                const totalWeiInEth = (3000000 + 800000 + 5000000) * (10**-9)
                const totalEthCosts = totalWeiInEth * priceOfEth
                const totalCostInDollars = (totalEthCosts + initialCollateralInDollars).toFixed(2)

                this.setState({
                    totalCost: totalCostInDollars
                })
            }
        }
    }

    refreshPrices = async () => {
        let priceData;
        try {
            console.log( 'Refreshing prices' )
            priceData = await axios.get(`${backendPath}/tests/refresh-prices`);
            priceData = priceData.data
            console.log( 'PRICE DATA: ', priceData )
        } catch ( err ) {
            console.error( `Error fetching prices` );
        }

        if ( priceData && priceData.length ) {
            this.setState({
                currentUnderlyingPrices: priceData
            })
        }
    }

    render() {
    // console.log( this.state )
    // const coinbaseAuthUrl = process.env.COINBASE_AUTH_URL || config.coinbaseAuthUrl;

    return (
        <div>
            { this.state.updatingDashboard === false ? 
            <div className="admin">
                {this.state.currentTab && this.state.currentTab !== '' ? 
                    <nav className="admin__nav">
                        <a href="/" className="logo">
                            <img src={greenwoodLogo} alt="Greenwood" width="180" height="28"/>
                        </a>

                        <ul style={{textAlign: 'center'}}>
                        {/* <p style={{color:"#76808f", fontFamily:"Roboto"}}>{this.state.currentAccount ? 'Connected to account:' : null}</p> */}
                        <p className="logged-in-as"style={{marginBottom:"10%", color:"#FFFFFF"}}><Badge style={{color: '#66676F' }}className={`${ this.state.currentAccount ? 'web3Connected' : 'web3NotConnected' }`}>{this.state.currentAccountTrunc ? this.state.currentAccountTrunc : 'No wallet connected'}</Badge></p>                
                        <p style={{padding: "0rem 2rem", color: "#ffffff", textAlign: "left", fontSize: "0.8rem"}}>ETH Balance: { this.state.ethBalance ? this.state.ethBalance : null } </p>     
                        <p style={{padding: "0rem 2rem", color: "#ffffff", textAlign: "left", fontSize: "0.8rem"}}>DAI Balance: { this.state.daiBalance ? this.state.daiBalance : null } </p>
                        <div className="separator" style={{padding: "0 2rem"}}></div>

                        </ul>
                        
                        <ul className="menu">
                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Portfolio') ? 'active' : '' }`} id="Portfolio" onClick={this.handleTabChange}>Portfolio overview</a>  
                            </li>

                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Create') ? 'active' : '' }`} id="Create" onClick={this.handleTabChange}>Contract builder</a>
                            </li>

                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Market') ? 'active' : '' }`} id="Market" onClick={this.handleTabChange}>Trading desk</a>
                            </li>

                        </ul>
                    </nav>
            
                :
                    null 
                }
            
                {this.state.currentTab === 'Portfolio' &&  
                    <main className="admin__main">
                        { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === false && 
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                                    <Form id="buy-dai-form" style={{width: "100%"}}>
                                        {/* <h4 className="graphik-bold" style={{textAlign: "center", color: "white", marginTop:"2%"}}><span className='pull-left'>Get Started</span><span className='pull-right'></span></h4> */}
                                        <div style={{color:'white'}}>
                                            <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                            <div className="pull-right">
                                                    {/* <span id='Login' style={{fontSize:'0.5em'}}>{this.authModalSignup ? 'Already have an account?' : 'Don\'t have an account?'} <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>{this.authModalSignup ? 'Log in' : 'Sign up'}</span></a></span> */}
                                                    {this.state.authModalSignup === true &&
                                                        <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                                    }

                                                    {
                                                        this.state.authModalSignup === false &&
                                                        <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                                    }
                                            </div>
                                        </div>

                                        <hr style={{backgroundColor:'#2D2F3A'}}/>

                                        <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                                <div>
                                                    {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                                </div>
                                                <div style={{fontSize: '0.6em'}}>
                                                    Powered by Fortmatic
                                                </div>
                                            </Button>
                                        </FormGroup>

                                        <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                        <div className="">
                                            <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                        </div>

                                        <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                                <div>
                                                    MetaMask
                                                </div>
                                            </Button>
                                        </FormGroup>

                                    </Form>
                                </div>
                            </div>
                        }

                        { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === true && this.state.hasGreenwoodContracts === false &&
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>

                                        {/* Welcome to Greenwood! Unlock your Portfolio by buying, selling, or creating a contract! */}
                                        <span className="graphik-bold" style={{fontSize:'1em', textAlign:'center', color:'white'}}>Welcome to Greenwood</span>
                                        <hr style={{backgroundColor:'#2D2F3A'}}/>
                                        <span style={{color:'#FFFFFF', textAlign:'center', fontSize:'1.3rem'}}>Unlock your portfolio by:</span>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-buy">
                                                        Buying a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-sell">
                                                        Selling a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}><Button className="fortmatic-form-button lift" type="button">
                                                <div id="Create-welcome">
                                                    Creating a contract
                                                </div>
                                            </Button></a>
                                        </div>


                                </div>
                            </div>
                        }

                        <Modal isOpen={this.state.showCurrentContractModal} toggle={ ( e ) => this.toggleCurrentContractModal('', '', '', '', '', '', '', '', '', '', '', e ) } className="modal-card aligner offer-detail-modal" style={{background: "transparent", position: "absolute", left: "50%", top:"47%", transform: "translateX(calc(-50% + 125px)) translateY(-50%)", maxHeight:"80%", padding:'2%'}}>
                            <div style={{textAlign: "center"}} toggle={ ( e ) => this.toggleCurrentContractModal('', '', '', '', '', '', '', '', '', '', '', e ) } charcode="">
                                <h3 className="dashboard-item-header">Contract Details</h3>
                            </div>
                            <div className="separator"></div>
                            <ModalBody>
                            <Form>
                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Position</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalPosition && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalPosition}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Contract Type</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalContractType && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalContractType}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Underlying asset</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalUnderlyingAsset && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalUnderlyingAsset}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Price provider</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalPriceProvider && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalPriceProvider}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Strike price</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalStrikePrice && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>$ {this.state.currentContractModalStrikePrice}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Size</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalSize && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalSize}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Collateralization ratio</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalCollateralizationRatio && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.currentContractModalCollateralizationRatio} %</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Update frequency</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalUpdateFrequency && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>Daily</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Expiry</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalExpiry && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{moment(this.state.currentContractModalExpiry.toString()).format("dddd MMM D, HH:mm UTC")}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Current collateralization</Label>
                                        <Col sm={8}>
                                            {this.state.currentContractModalCollateralRequirement && <p className="roboto-regular color-white" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)"}}>{this.state.currentContractModalCollateralRequirement} %</p>}
                                        </Col>
                                    </FormGroup>

                                    { this.state.isManagingCollateral && <div>
                                        <div className="separator"></div>
                                        <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Amount in DAI</Label>
                                        <Col sm={8}>
                                        <Input type="number" name="currentContractModalManageCollateralAmount" id="currentContractModalManageCollateralAmount" placeholder="--" onChange={this.handleChange} className="deposit-withdraw-modal-input"/>
                                        </Col>
                                    </FormGroup>
                                    </div>} 

                            
                                    </Form>
                            </ModalBody>
                            { !this.state.isManagingCollateral && <div className="Aligner" style={{height: "100%", textAlign: "center", display: "block", marginTop: "1%"}}>
                                <Button className='access-button roboto-regular' onClick={ this.manageCollateral } style={{margin: "1%", width: "210px"}}>Manage collateral</Button>
                            </div> }
                            { this.state.isManagingCollateral && 
                            <div className="Aligner" style={{height: "100%", textAlign: "center", display: "block", marginTop: "1%"}}>
                                {!this.state.isWithdrawingCollateral && !this.state.collateralWithdrawn && !this.state.isDepositingCollateral && !this.state.collateralDeposited && this.state.collateralTxHash === '' && <Button className='access-button roboto-regular' onClick={ this.depositCollateral } style={{margin: "1%", width: "210px"}}>Deposit collateral</Button>}
                                {!this.state.isWithdrawingCollateral && !this.state.collateralWithdrawn && !this.state.isDepositingCollateral && !this.state.collateralDeposited && this.state.collateralTxHash === '' && <Button className='access-button roboto-regular' onClick={ this.withdrawCollateral } style={{margin: "1%", width: "210px"}}>Withdraw collateral</Button>}
                                { (this.state.isDepositingCollateral || this.state.isWithdrawingCollateral ) && <h5 className="graphik-bold color-orange">DO NOT CLOSE THIS MODAL UNTIL THE TRANSACTION IS COMPLETE!</h5> }
                                { (this.state.isDepositingCollateral || this.state.isWithdrawingCollateral ) && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                { (this.state.collateralDeposited || this.state.collateralWithdrawn) && this.state.collateralTxHash && <h6 className="color-white">Transaction hash: <a href={`https://kovan.etherscan.io/tx/${this.state.collateralTxHash}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>{this.state.collateralTxHash} <i class="fas fa-external-link-alt"></i></a></h6>}
                            </div>
                            }
                        </Modal>


                        <Modal isOpen={this.state.showUnfilledContractModal} toggle={ ( e ) => this.toggleUnfilledContractModal('', '', '', '', '', '', '', '', '', '', '', e ) } className="modal-card aligner offer-detail-modal" style={{background: "transparent", position: "absolute", left: "50%", top:"47%", transform: "translateX(calc(-50% + 125px)) translateY(-50%)", maxHeight:"80%", padding:'2%'}}>
                            <div style={{textAlign: "center"}} toggle={ ( e ) => this.toggleUnfilledContractModal('', '', '', '', '', '', '', '', '', '', '', e ) } charcode="">
                                <h3 className="dashboard-item-header">Contract Details</h3>
                            </div>
                            <div className="separator"></div>
                            <ModalBody>
                            <Form>
                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Position</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalPosition && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalPosition}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Contract Type</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalContractType && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalContractType}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Underlying asset</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalUnderlyingAsset && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalUnderlyingAsset}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Price provider</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalPriceProvider && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalPriceProvider}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Strike price</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalStrikePrice && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>$ {this.state.unfilledContractModalStrikePrice}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Size</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalSize && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalSize}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Collateralization ratio</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalCollateralizationRatio && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.unfilledContractModalCollateralizationRatio} %</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Update frequency</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalUpdateFrequency && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>Daily</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Expiry</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalExpiry && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{moment(this.state.unfilledContractModalExpiry.toString()).format("dddd MMM D, HH:mm UTC")}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Current collateralization</Label>
                                        <Col sm={8}>
                                            {this.state.unfilledContractModalCollateralRequirement && <p className="roboto-regular color-white" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)"}}>{this.state.unfilledContractModalCollateralRequirement} %</p>}
                                        </Col>
                                    </FormGroup>

                                    {/* { this.state.isManagingCollateral && <div>
                                        <div className="separator"></div>
                                        <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Amount in DAI</Label>
                                        <Col sm={8}>
                                        <Input type="number" name="currentContractModalManageCollateralAmount" id="currentContractModalManageCollateralAmount" placeholder="--" onChange={this.handleChange} className="deposit-withdraw-modal-input"/>
                                        </Col>
                                    </FormGroup>
                                    </div>}  */}

                            
                                    </Form>
                            </ModalBody>
                            {/* { !this.state.isManagingCollateral && <div className="Aligner" style={{height: "100%", textAlign: "center", display: "block", marginTop: "1%"}}>
                                <Button className='access-button roboto-regular' onClick={ this.manageCollateral } style={{margin: "1%", width: "210px"}}>Manage collateral</Button>
                            </div> } */}

                            <div className="Aligner" style={{height: "100%", textAlign: "center", display: "block", marginTop: "1%"}}>
                                {!this.state.isClosingContract && !this.state.isContractClosed && <Button className='access-button roboto-regular' onClick={ this.closeContract } style={{margin: "1%", width: "210px"}}>Close contract</Button>}
                                {this.state.isClosingContract && <h5 className="graphik-bold color-orange">DO NOT CLOSE THIS MODAL UNTIL THE TRANSACTION IS COMPLETE!</h5> }
                                {this.state.isClosingContract && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                {this.state.isContractClosed && this.state.closedTxHash && <h6 className="color-white">Transaction hash: <a href={`https://kovan.etherscan.io/tx/${this.state.closedTxHash}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>{this.state.closedTxHash} <i class="fas fa-external-link-alt"></i></a></h6>}
                            </div>
                        </Modal>

                        <div style={{height: "55px"}}>
                            <h4 className="admin-header" style={{verticalAlign: "middle"}}>Portfolio Overview</h4>
                            <div class="separator"></div>
                        </div>

                        <div className="dashboard">

                            {/* <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Portfolio value</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioValue ? this.state.portfolioValue : '$ 0.00' }
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Portfolio yield</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '0.00 %'}
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 hr change</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.dailyValue ? this.state.dailyValue : '$0.00'}
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 hr yield</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.dailyYield ? this.state.dailyYield : '0.00 %'}
                                </div>
                            </div> */}


                            <div className="dashboard__item--chart">
                                <Row>
                                    <Col sm={6}>
                                        <h5 className="dashboard-item-header">Current Prices</h5>
                                    </Col>
                                    <Col sm={6} style={{verticalAlign: "middle"}}>
                                        <Button className="refresh-button roboto-regular float-right" onClick={this.refreshPrices}>Refresh prices</Button>
                                    </Col>
                                </Row>

                                {/* <h5 className="dashboard-item-header">Portfolio value</h5>
                                <div className="card gw-card-dark-flat">
                                    <div className="coming-soon-overlay aligner">
                                        <div className="aligner-item">
                                            <h5 className="dashboard-item-header-grey">Under construction</h5>
                                        </div>
                                    </div>
                                        { this.state.lineChartData ? <LineChart data={this.state.lineChartData} /> : null }
                                </div> */}
                                <div className="card gw-card-dark-flat">
                                    <div><Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.currentUnderlyingPrices && this.state.currentUnderlyingPrices.length !== 0 ? this.state.currentUnderlyingPrices.map(function(item, key) {
                                            return (
                                                <tr key = {key}>
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.price}</td>
                                                </tr>
                                            )
                                        })

                                        :

                                        <tr>
                                            <td colSpan="2" style={{textAlign: "center"}}>No price data</td>
                                        </tr>

                                                                    
                                        }
                                        {/* <tr>
                                            <td colSpan="2" style={{textAlign: "center"}}>More assets coming soon</td>
                                        </tr> */}
                                        </tbody>
                                    </Table></div>
                                </div>
                            </div>

                            <div className="dashboard__item--chart">
                                <h5 className="dashboard-item-header">Portfolio composition</h5>
                                <div className="card gw-card-dark-flat ">
                                    { this.state.doughnutChartData.labels && this.state.doughnutChartData.datasets ? <DoughnutChart data={this.state.doughnutChartData}/> : null }
                                </div>
                            </div>

                            <div className="dashboard__item dashboard__item--full">
                                <h5 className="dashboard-item-header">Current contracts</h5>
                                <div className="card gw-card-dark-flat">
                                    <div><Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Strike price</th>
                                                <th>Size</th>
                                                <th>Update frequency</th>
                                                <th>Collateralization ratio</th>
                                                <th>Collateral requirement</th>
                                                <th>Expiry</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.currentContracts && this.state.currentContracts.length !== 0 ? this.state.currentContracts.map(function(item, key) {
                                            return (
                                                <tr key = {key} style={{cursor: "pointer"}}  onClick={ ( e ) => this.toggleCurrentContractModal(item.position, 'Forward', item.symbol, 'Greenwood', item.strikePrice, item.size, item.minimumCollateralizationRatio, item.updateFrequencyHr, item.expirationTime, Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( item.strikePrice ) ) ).toFixed(2), item.contractAddress, e ) }>
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.strikePrice}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.updateFrequencyHr}</td>
                                                    <td>{item.minimumCollateralizationRatio} %</td>
                                                    <td>Đ { Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( item.strikePrice ) ) ).toFixed(2) }</td>
                                                    <td>{moment(item.expirationTime).format("dddd MMM D, HH:mm UTC")}</td>
                                                    <td><a href={`https://kovan.etherscan.io/address/${item.contractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"#66676F"}}><i class="fas fa-external-link-alt"></i></a></td>
                                                </tr>
                                            )
                                        }.bind(this))

                                        :

                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center"}}>You have no open contracts</td>
                                        </tr>

                                                                    
                                        }</tbody>
                                    </Table></div>
                                </div>
                            </div>


                            <div className="dashboard__item dashboard__item--full">
                                <h5 className="dashboard-item-header">Unfilled contracts</h5>
                                <div className="card gw-card-dark-flat">
                                    <div><Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Strike price</th>
                                                <th>Size</th>
                                                <th>Update frequency</th>
                                                <th>Collateralization ratio</th>
                                                <th>Collateral requirement</th>
                                                <th>Expiry</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.unfilledContracts && this.state.unfilledContracts.length !== 0 ? this.state.unfilledContracts.map(function(item, key) {
                                            return (
                                                <tr key = {key} style={{cursor: "pointer"}}  onClick={ ( e ) => this.toggleUnfilledContractModal(item.position, 'Forward', item.symbol, 'Greenwood', item.strikePrice, item.size, item.minimumCollateralizationRatio, item.updateFrequencyHr, item.expirationTime, Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( item.strikePrice ) ) ).toFixed(2), item.contractAddress, e ) }>
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.strikePrice}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.updateFrequencyHr}</td>
                                                    <td>{item.minimumCollateralizationRatio} %</td>
                                                    <td>Đ { Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( item.strikePrice ) ) ).toFixed(2) }</td>
                                                    <td>{moment(item.expirationTime).format("dddd MMM D, HH:mm UTC")}</td>
                                                    <td><a href={`https://kovan.etherscan.io/address/${item.contractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"#66676F"}}><i class="fas fa-external-link-alt"></i></a></td>
                                                </tr>
                                            )
                                        }.bind(this))

                                        :

                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center"}}>You have no unfilled contracts</td>
                                        </tr>

                                                                    
                                        }</tbody>
                                    </Table></div>
                                </div>
                            </div>


                            <div className="dashboard__item dashboard__item--full">
                                <h5 className="dashboard-item-header">Transaction history</h5>
                                <div className="card gw-card-dark-flat">
                                    <Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Strike price</th>
                                                <th>Size</th>
                                                <th>Update frequency</th>
                                                <th>Collateralization ratio</th>
                                                <th>Collateral requirement</th>
                                                <th>Expiry</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.transactionHistory && this.state.transactionHistory.length !== 0 ? this.state.transactionHistory.map(function(item, key) {
                                            return (
                                                <tr key = {key} >
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.strikePrice}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.updateFrequencyHr}</td>
                                                    <td>{item.minimumCollateralizationRatio} %</td>
                                                    <td>Đ { Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( item.strikePrice ) ) ).toFixed(2) }</td>
                                                    <td>{moment(item.expirationTime).format("dddd MMM D, HH:mm UTC")}</td>
                                                    <td><a href={`https://kovan.etherscan.io/address/${item.contractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"#66676F"}}><i class="fas fa-external-link-alt"></i></a></td>
                                                </tr>
                                            )
                                        })

                                        :

                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center"}}>You have no transaction history</td>
                                        </tr>
                                    
                                    
                                    }</tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </main> 
                }

                { this.state.currentTab === 'Market' &&
                    <main className="admin__main">
                        { this.state.currentTab === 'Market' && this.state.hasWeb3Provider === false && 
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                                    <Form id="buy-dai-form" style={{width: "100%"}}>
                                        {/* <h4 className="graphik-bold" style={{textAlign: "center", color: "white", marginTop:"2%"}}><span className='pull-left'>Get Started</span><span className='pull-right'></span></h4> */}
                                        <div style={{color:'white'}}>
                                            <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                            <div className="pull-right">
                                                    {/* <span id='Login' style={{fontSize:'0.5em'}}>{this.authModalSignup ? 'Already have an account?' : 'Don\'t have an account?'} <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>{this.authModalSignup ? 'Log in' : 'Sign up'}</span></a></span> */}
                                                    {this.state.authModalSignup === true &&
                                                        <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                                    }

                                                    {
                                                        this.state.authModalSignup === false &&
                                                        <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                                    }
                                            </div>
                                        </div>

                                        <hr style={{backgroundColor:'#2D2F3A'}}/>

                                        <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                                <div>
                                                    {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                                </div>
                                                <div style={{fontSize: '0.6em'}}>
                                                    Powered by Fortmatic
                                                </div>
                                            </Button>
                                        </FormGroup>

                                        <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                        <div className="">
                                            <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                        </div>

                                        <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                                <div>
                                                    MetaMask
                                                </div>
                                            </Button>
                                        </FormGroup>

                                    </Form>
                                </div>
                            </div>
                        }

                        <Modal isOpen={this.state.showOfferDetailModal} toggle={ ( e ) => this.toggleOfferDetailModal('', '', '', '', '', '', '', '', '', '', '', e ) } className="modal-card aligner offer-detail-modal" style={{background: "transparent", position: "absolute", left: "50%", top:"47%", transform: "translateX(calc(-50% + 125px)) translateY(-50%)", maxHeight:"80%", padding:'2%'}}>
                            {/* <ModalHeader toggle={this.toggleOfferDetailModal} charCode="">
                                <div style={{textAlign: "center"}}>
                                <h5 className="dashboard-item-header">Contract details</h5>
                                </div>
                            </ModalHeader> */}

                            <div style={{textAlign: "center"}} toggle={ ( e ) => this.toggleOfferDetailModal('', '', '', '', '', '', '', '', '', '', '', e ) } charcode="">
                                <h3 className="dashboard-item-header">Contract Details</h3>
                            </div>
                            <div className="separator"></div>
                            <ModalBody>
                            <Form>
                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Position</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalPosition && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalPosition}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Contract Type</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalContractType && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalContractType}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Underlying asset</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalUnderlyingAsset && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalUnderlyingAsset}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Price provider</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalPriceProvider && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalPriceProvider}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Strike price</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalStrikePrice && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>$ {this.state.detailModalStrikePrice}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Size</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalSize && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalSize}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Collateralization ratio</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalCollateralizationRatio && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{this.state.detailModalCollateralizationRatio} %</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Update frequency</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalUpdateFrequency && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>Daily</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Expiry</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalExpiry && <p className="roboto-regular" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)", color: "white"}}>{moment(this.state.detailModalExpiry.toString()).format("dddd MMM D, HH:mm UTC")}</p>}
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginBottom: "0"}}>
                                        <Label for="exampleEmail" className="create-form-input-label color-orange" sm={4}>Collateral required</Label>
                                        <Col sm={8}>
                                            {this.state.detailModalCollateralRequirement && <p className="roboto-regular color-orange" style={{textAlign: "right", marginBottom: "0", paddingTop: "calc(.375rem + 1px)", paddingBottom: "calc(.375rem + 1px)"}}>Đ {this.state.detailModalCollateralRequirement}</p>}
                                        </Col>
                                    </FormGroup>

                            
                                    </Form>
                            </ModalBody>
                            <div style={{height: "100%"}} className="Aligner" style={{textAlign: "center", display: "block", marginTop: "1%"}}>
                                {!this.state.isFillingContract && !this.state.contractFilled && this.state.filledContractTxHash === '' && <Button className='access-button roboto-regular' onClick={ this.fillOrder }>Fill contract</Button>}
                                { this.state.isFillingContract && <h5 className="graphik-bold color-orange">DO NOT CLOSE THIS MODAL UNTIL THE TRANSACTION IS COMPLETE!</h5> }
                                { this.state.isFillingContract && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                { this.state.contractFilled && this.state.filledContractTxHash && <h6 className="color-white">Transaction hash: <a href={`https://kovan.etherscan.io/tx/${this.state.filledContractTxHash}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>{this.state.filledContractTxHash} <i class="fas fa-external-link-alt"></i></a></h6>}
                            </div>

                            {/* <div style={{height: "100%"}} className="Aligner">
                                <div className="Aligner-item" style={{paddingTop:"10%", maxWidth: "100%"}}>
                                    { this.state.isFillingContract && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                    { this.state.contractFilled && this.state.filledContractAddress && <h6>Filled contract at address: <a href={`https://kovan.etherscan.io/address/${this.state.filledContractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>{this.state.filledContractAddress} <i class="fas fa-external-link-alt"></i></a></h6>}
                                </div>
                            </div> */}
                        </Modal>


                        <div style={{height: "55px", }}>
                            <h4 className="admin-header">Trading desk</h4>
                            <div class="separator"></div>
                        </div>

                        <div className="dashboard">
                            {/* <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Value Locked</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.compoundBalance ? this.state.compoundBalance : '--' }
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 Hr % Change</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Long Allocation</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Short Allocation</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <br/> */}
                            <div className="dashboard__item--full">
                                {/* <h5 className="dashboard-item-header">Manage</h5> */}
                                <div className="card form-card" style={{background:"transparent !important"}}>
                                <Nav tabs>
                                    <NavItem className="nav-item-50-percent">
                                        <NavLink className={`${ this.checkActiveCompoundTab('Deposit') ? 'active deposit-tab' : 'deposit-tab' }`} href="#" id="Deposit" onClick={this.handleCompoundTabChange}>Buy Offers</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-item-50-percent">
                                        <NavLink className={`${ this.checkActiveCompoundTab('Withdraw') ? 'active withdraw-tab' : 'withdraw-tab' }`} href="#" id="Withdraw" onClick={this.handleCompoundTabChange}>Sell Offers</NavLink>
                                    </NavItem>
                                </Nav>

                                <div style={{height: "75vh", overflow: "scroll"}}><Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Strike price</th>
                                                <th>Size</th>
                                                <th>Update frequency</th>
                                                <th>Collateralization ratio</th>
                                                <th>Collateral requirement</th>
                                                <th>Expiry</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        { this.checkActiveCompoundTab('Withdraw') && <tbody> { this.state.unfilledBuyerContracts && this.state.unfilledBuyerContracts.length > 0 ? this.state.unfilledBuyerContracts.map(function(item, key) {
                                            return (
                                                <tr key = {key} style={{cursor: "pointer"}} onClick={ ( e ) => this.toggleOfferDetailModal('Sell', 'Forward', item.symbol, 'Greenwood', item.strikePrice, item.size, item.minimumCollateralizationRatio, item.updateFrequencyHr, item.expirationTime, Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( this.state.wtiPrice ) ) ).toFixed(2), item.contractAddress,e ) }>
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.strikePrice}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.updateFrequencyHr}</td>
                                                    <td>{item.minimumCollateralizationRatio} %</td>
                                                    <td>Đ { Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( this.state.wtiPrice ) ) ).toFixed(2) }</td>
                                                    <td>{moment(item.expirationTime).format("dddd MMM D, HH:mm UTC")}</td>
                                                    <td><a href={`https://kovan.etherscan.io/address/${item.contractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"#66676F"}}><i class="fas fa-external-link-alt"></i></a></td>
                                                </tr>
                                            )
                                        }.bind(this))

                                        :

                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center"}}><h5 className="roboto-regular" style={{color: "#66676F", fontSize: "16px"}}>There are no sell offers. <u><span><a id="Create" onClick={this.handleTabChange} style={{color:"white"}}>Create one here</a></span></u></h5></td>
                                        </tr>

                                        
                                        
                                        }</tbody>}

                                        { this.checkActiveCompoundTab('Deposit') && <tbody> {this.state.unfilledSellerContracts && this.state.unfilledSellerContracts.length > 0 ? this.state.unfilledSellerContracts.map(function(item, key) {
                                            return (
                                                <tr key = {key} style={{cursor: "pointer"}} onClick={ ( e ) => this.toggleOfferDetailModal('Buy', 'Forward', item.symbol, 'Greenwood', item.strikePrice, item.size, item.minimumCollateralizationRatio, item.updateFrequencyHr, item.expirationTime, Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( this.state.wtiPrice ) ) ).toFixed(2), item.contractAddress, e ) }>
                                                    <td>{item.symbol}</td>
                                                    <td>$ {item.strikePrice}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.updateFrequencyHr}</td>
                                                    <td>{item.minimumCollateralizationRatio} %</td>
                                                    <td>Đ { Math.ceil( ( Number(item.minimumCollateralizationRatio / 100.00 ) ) * ( Number(item.size ) * Number( this.state.wtiPrice ) ) ).toFixed(2) }</td>
                                                    <td>{moment(item.expirationTime).format("dddd MMM D, HH:mm UTC")}</td>
                                                    <td><a href={`https://kovan.etherscan.io/address/${item.contractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"#66676F"}}><i class="fas fa-external-link-alt"></i></a></td>
                                                </tr>
                                            )
                                        }.bind(this))

                                        : 

                                        <tr>
                                            <td colspan="8" style={{textAlign: "center"}}><h5 className="roboto-regular" style={{color: "#66676F", fontSize: "16px"}}>There are no buy offers. <u><span><a id="Create" onClick={this.handleTabChange} style={{color:"white"}}>Create one here</a></span></u></h5></td>
                                        </tr>
                                        
                                        }</tbody>}
                                </Table></div>

                                </div>
                            </div>
                        </div>
                    </main> 
                }



                { this.state.currentTab === 'Create' &&
                    <main className="admin__main">
                       { this.state.currentTab === 'Create' && this.state.hasWeb3Provider === false && 
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                                    <Form id="buy-dai-form" style={{width: "100%"}}>
                                        {/* <h4 className="graphik-bold" style={{textAlign: "center", color: "white", marginTop:"2%"}}><span className='pull-left'>Get Started</span><span className='pull-right'></span></h4> */}
                                        <div style={{color:'white'}}>
                                            <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                            <div className="pull-right">
                                                    {/* <span id='Login' style={{fontSize:'0.5em'}}>{this.authModalSignup ? 'Already have an account?' : 'Don\'t have an account?'} <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>{this.authModalSignup ? 'Log in' : 'Sign up'}</span></a></span> */}
                                                    {this.state.authModalSignup === true &&
                                                        <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                                    }

                                                    {
                                                        this.state.authModalSignup === false &&
                                                        <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                                    }
                                            </div>
                                        </div>

                                        <hr style={{backgroundColor:'#2D2F3A'}}/>

                                        <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                                <div>
                                                    {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                                </div>
                                                <div style={{fontSize: '0.6em'}}>
                                                    Powered by Fortmatic
                                                </div>
                                            </Button>
                                        </FormGroup>

                                        <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                        <div className="">
                                            <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                        </div>

                                        <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                                <div>
                                                    MetaMask
                                                </div>
                                            </Button>
                                        </FormGroup>

                                    </Form>
                                </div>
                            </div>
                        }

                        { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === true && this.state.hasGreenwoodContracts === false &&
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>

                                        {/* Welcome to Greenwood! Unlock your Portfolio by buying, selling, or creating a contract! */}
                                        <span className="graphik-bold" style={{fontSize:'1em', textAlign:'center', color:'white'}}>Welcome to Greenwood</span>
                                        <hr style={{backgroundColor:'#2D2F3A'}}/>
                                        <span style={{color:'#FFFFFF', textAlign:'center', fontSize:'1.3rem'}}>Unlock your portfolio by:</span>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-buy">
                                                        Buying a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-sell">
                                                        Selling a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}><Button className="fortmatic-form-button lift" type="button">
                                                <div id="Create-welcome">
                                                    Creating a contract
                                                </div>
                                            </Button></a>
                                        </div>


                                </div>
                            </div>
                        }

                        <div style={{height: "55px"}}>
                            <h4 className="admin-header" style={{verticalAlign: "middle"}}>Contract Builder</h4>
                            <div class="separator"></div>
                        </div>

                        <div className="dashboard">
                            
                            <div className="dashboard__item--chart">
                            <h5 className="dashboard-item-header">Contract terms</h5>
                                <h5 className="dashboard-item-header"></h5>
                                <div className="card gw-card-dark-flat">
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Position
                                        <FormText  className="desc-text">
                                            The position you are taking
                                        </FormText>
                                        </Label>
                                        <Col sm={8}>
                                            <div>
                                            <CustomInput className="create-form-radio-label" type="radio" id="exampleCustomInline" label="Buy" value="buyer" inline checked={this.state.selectedPosition === 'buyer'} onChange={this.changeRadio} />
                                            <CustomInput className="create-form-radio-label" type="radio" id="exampleCustomInline2" label="Sell" value="seller" inline checked={this.state.selectedPosition === 'seller'} onChange={this.changeRadio} />
                                            </div>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Contract Type
                                        <FormText  className="desc-text">
                                            The contract template
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                        <Input type="select" name="selectedContractType" id="selectedContractType" value={this.state.selectedContractType} onChange={this.handleChange}>
                                        <option value='' disabled defaultValue>Select a contract type</option>
                                        <option value='forward' name="forward">Forward</option>
                                        </Input>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Underlying asset
                                        <FormText  className="desc-text">
                                            The asset that the contract is based on
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                        <Input type="select" name="selectedUnderlyingAsset" id="selectedUnderlyingAsset" value={this.state.selectedUnderlyingAsset} onChange={this.handleChange}>
                                        <option value='' disabled defaultValue>Select an underlying asset</option>
                                        <option name="WTI" value='WTI'>Oil (WTI)</option>
                                        </Input>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>
                                    

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Price provider
                                        <FormText  className="desc-text">
                                            The source for price data for the contract
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                        <Input type="select" name="selectedPriceProvider" id="selectedPriceProvider" value={this.state.selectedPriceProvider} onChange={this.handleChange}>
                                        <option value='' disabled defaultValue>Select a price provider</option>
                                        <option value='forward' name="forward">Greenwood</option>
                                        </Input>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Strike price
                                            <FormText  className="desc-text">
                                                Buy/Sell price of the asset, must be a positive integer
                                            </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                            <Input type="number" name="selectedStrikePrice" id="selectedStrikePrice" placeholder="--" onChange={this.handleChange} step="1"/>  
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Size
                                        <FormText  className="desc-text">
                                            The quantity of the trade, must be positive (up to 2 decimal places ex. 1.35)
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                            <Input type="number" name="selectedSize" id="selectedSize" placeholder="--" onChange={this.handleChange}/>  
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Min. Collateralization
                                        <FormText  className="desc-text">
                                            Percentage of collateral required to prevent liquidation , must be a positive integer
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                            <Input type="number" name="selectedMinimumCollateralizationRatio" id="selectedMinimumCollateralizationRatio" placeholder="--" onChange={this.handleChange} step="1"/>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Initial Collateralization
                                        <FormText  className="desc-text">
                                            Percentage of collateral to lock on issuance , must be a positive integer
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                            <Input type="number" name="selectedInitialCollateralizationRatio" id="selectedInitialCollateralizationRatio" placeholder="--" onChange={this.handleChange} step="1"/>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Update frequency
                                        <FormText  className="desc-text">
                                            How often price data gets sent to the contract
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                        <Input type="select" name="selectedUpdateFrequency" id="selectedUpdateFrequency" value={this.state.selectedUpdateFrequency} onChange={this.handleChange}>
                                        <option value='' disabled defaultValue>Select the price update frequency</option>
                                        {/* <option value="daily" name="daily">Daily</option> */}
                                        <option value="daily" name="daily">Daily</option>
                                        </Input>
                                        </Col>
                                    </FormGroup>

                                    <div className="separator"></div>

                                    <FormGroup row style={{marginTop: "1rem"}}>
                                        <Label for="exampleEmail" className="create-form-input-label" sm={4}>Duration
                                        <FormText  className="desc-text">
                                            How long the contract will be active
                                        </FormText>
                                        </Label>
                                        <Col sm={8} style={{display: "flex", alignItems: "center"}}>
                                        <Input type="select" name="selectedDuration" id="selectedDuration" value={this.state.selectedDuration} onChange={this.handleChange}>
                                        <option value='' disabled defaultValue>Select a duration for this contract</option>
                                        {/* <option value="86400" name="selectedDuration">1 day</option> */}
                                        <option value="86400" name="selectedDuration">1 day</option>
                                        </Input>
                                        </Col>
                                    </FormGroup>

                                    

                                    </Form>
                                </div>
                            </div>

                            <div className="dashboard__item--chart">
                                <h5 className="dashboard-item-header" style={{textAlign:"left"}}>Issuance cost</h5>
                                <div className="card gw-card-dark-flat-outline ">
                                    <div className="">
                                        <Form>
                                            <FormGroup row>
                                                <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Collateral required</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="initialCollateral" id="initialCollateral" value={this.state.initialCollateral ? `${this.state.initialCollateral}Đ` : '--'} disabled style={{textAlign:"center"}}/>  
                                                </Col>
                                            </FormGroup>
                                    

                                            <div class="separator"></div>

                                            <FormGroup row style={{marginTop: "1rem"}}>
                                                <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Deployment gas</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="size" id="size" value={this.state.initialCollateral ? "~3000000 wei" : "--"} disabled style={{textAlign:"center"}}/>  
                                                </Col>
                                            </FormGroup>

                                            <div class="separator"></div>

                                            <FormGroup row style={{marginTop: "1rem"}}>
                                                <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Maintenence gas</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="size" id="size" value={this.state.initialCollateral ? "~850000 wei" : "--"}disabled style={{textAlign:"center"}}/>  
                                                </Col>
                                            </FormGroup>

                                            <div class="separator"></div>

                                            <FormGroup row style={{marginTop: "1rem"}}>
                                                <Label for="exampleEmail" className="create-form-input-label" sm={4}>Total issuance cost</Label>
                                                <Col sm={8}>
                                                    {this.state.totalCost && <h1 className="graphik-bold color-orange" style={{textAlign:"left"}}>${this.state.totalCost}</h1>}
                                                </Col>
                                            </FormGroup>
                                            <div style={{textAlign:"center"}}>
                                            <Button className="access-button roboto-regular" href="" style={{textAlign:"center"}} onClick={this.submitContractTerms}>Deploy contract to Ethereum</Button>
                                            </div>

                                            <div style={{height: "100%"}} className="Aligner">
                                                <div className="Aligner-item" style={{paddingTop:"10%", maxWidth: "100%"}}>
                                                    { this.state.isDeployingContract && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                                    {/* { this.state.contractDeployed && this.state.deployedContractAddress && <h6>Contract deployed at address: {this.state.deployedContractAddress} <a href={`https://kovan.etherscan.io/address/${this.state.deployedContractAddress}`} target="_blank" rel="noopener noreferrer"><i class="fas fa-external-link-alt"></i></a></h6> } */}
                                                    { this.state.contractDeployed && this.state.deployedContractAddress && <h6>Contract deployed at address: <a href={`https://kovan.etherscan.io/address/${this.state.deployedContractAddress}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>{this.state.deployedContractAddress} <i class="fas fa-external-link-alt"></i></a></h6>}
                                                    {/* <h6>Contract deployed at address: <a href="https://kovan.etherscan.io/address/0x642Aaa7B00D98A72f4833BbdF809298EE93C546D" target="_blank" rel="noopener noreferrer" style={{color:"white"}}>0x642Aaa7B00D98A72f4833BbdF809298EE93C546D <i class="fas fa-external-link-alt"></i></a></h6> */}
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </main> 
                }



            </div> 
            
            :
        
            // Div to show spinner when dashboard is updating
            <div style={{height: "100vh"}} className="Aligner oauth-aligner">
                <div className="Aligner-item">
                    <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
                </div>
            </div> 
        }
        </div>
    );
  }
}
export default withRouter(DemoDashboardContainer)