import React from 'react';
import DocsFuturesContainer from "../../components/DocsFuturesContainer/DocsFuturesContainer.jsx"
import './DocsFuturesView.css';

export default class DocsFuturesView extends React.Component {  

    render() {
        return (
            <div>
                <DocsFuturesContainer></DocsFuturesContainer>
            </div>
        )
    } 
}