import React, { } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, Link, useHistory, useLocation } from "react-router-dom";
import './App.css';

// import LandingView from './views/LandingView/LandingView.jsx'
import SynthLandingView from './views/SynthLandingView/SynthLandingView.jsx'
import SynthDashboardView from './views/SynthDashboardView/SynthDashboardView.jsx'
import DemoView from './views/DemoView/DemoView.jsx'
import TermsView from './views/TermsView/TermsView.jsx'
import PrivacyView from './views/PrivacyView/PrivacyView.jsx'
import RegistrationView from './views/RegistrationView/RegistrationView.jsx'
import LoginView from './views/LoginView/LoginView.jsx'
import DashboardView from './views/DashboardView/DashboardView.jsx'
import DemoDashboardView from './views/DemoDashboardView/DemoDashboardView.jsx'
import DemoVideoView from './views/DemoVideoView/DemoVideoView.jsx'
import OauthView from './views/OauthView/OauthView.jsx'
import DocsFuturesView from './views/DocsFuturesView/DocsFuturesView.jsx'

class App extends React.Component {
  render() {
    const hist = createBrowserHistory();
    return (
      <div className="app-container">
        <Router history={hist}>
          <Switch>
            {/* <Route exact path="/" component={ LandingView } /> */}
            {/* <Route exact path="/" render={(props) => <LandingView {...props} />} /> */}
            <Route exact path="/" render={(props) => <SynthLandingView {...props} />} />
            {/* <Route exact path="/yc-demo" render={(props) => <DemoVideoView {...props} />} /> */}
            <Route exact path="/demo" render={(props) => <DemoVideoView {...props} />} />
            <Route exact path="/alpha/c23C62DDE7b848e0e3bd9666a0D351B458517161" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/15aC14d2b00A14405C4b6f2934D230E958Bb085A" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/A155C81032E41D671A0bF3c5830368e3D704f531" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/9832E1a4A8726AEdAD2e76884FB6ffaA6460A6d8" render={(props) => <DemoDashboardView {...props} />} />

            <Route exact path="/alpha/9D52c5C859308cCf561256387291Ef45e7224AB1" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/5705a59c7fe1DC0c77e254ce75C597687158B3CF" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/8F4Dfc4Fa409cBDc40E0e93D136FD8BC694a3035" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/7AF8eD3f12AeE24332a99669c6c351C89AC3B124" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/89ADF182d27fc00a1F97D3885083EDF8AB47E5fb" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/8223F275959D7A7f31e523dae71E19f6560EC3A4" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/39B3893488054454830E2375280CCf8d91523c84" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/B7d1B7A51147c534D78E25590F59358D6C7b8476" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/cF89D9f1C61De59907ECE9e69E486C21906908Ea" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/6F7851B5A0BBE300b8891e0Fe12A73d24bc41551" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/f0DF7011D33818621fA9E5D230368aBb4d71Fe34" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/F6C06A4aC473f1Bd23B4B7712B42e170A4F64bEa" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/F879036eAB83415B28f9eB3A29d8372e2Bf4e27c" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/789967BACF98aB69b0bB8c22f524FF6c2077350B" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/0b97Ea657B2280cE91A1C40EfCc89697330bdd6B" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/C284c81CE336157c347ad72EaaE3A5E09f0C998a" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/3310223f87ac9753637C9D56a4961a04B988b167" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/bE782696B4897502222F27a20c05643Df627Dfa4" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/2C2CcE5F54242B1C3E6D682c5Bb0c5eBabe440f7" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/DAFF829eb50fDa550E778E3E006BaaeD5B6Ed349" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/fec97EaA9028633d8ccdf63F5D8f9e4fEb83D274" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/BAAcC33e9CA7473919D46D3D286BaE1698410b76" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/6Da0f105F234C0C18395F0631339aaC463eBD95A" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/2366FDcAA369a8dB84B14f475cbBf7cc7b21970B" render={(props) => <DemoDashboardView {...props} />} />
            <Route exact path="/alpha/cafD66C88AE977aAe13128a1DBe0B16A7355968A" render={(props) => <DemoDashboardView {...props} />} />

            <Route exact path="/terms" render={(props) => <TermsView {...props} />} />
            <Route exact path="/privacy" render={(props) => <PrivacyView {...props} />} />
            <Route exact path="/docs/forwards" render={(props) => <DocsFuturesView {...props} />} />
            <Route exact path="/register" render={(props) => <RegistrationView {...props} />} />
            <Route exact path="/login" render={(props) => <LoginView {...props} />} />
            <Route exact path="/dashboard" render={(props) => <SynthDashboardView {...props} />} />
            <Route exact path="/oauth/coinbase" render={(props) => <OauthView {...props} />} />
            <Redirect from="/*" to="/" />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

// function PrivateRoute({ children, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         fakeAuth.isAuthenticated ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }