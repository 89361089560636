import React from 'react';
import { withRouter } from "react-router-dom";
import { Table, Badge, Nav, NavItem, NavLink, FormGroup, Form, Input, Button, Spinner, Col, Label, CustomInput } from 'reactstrap';
import axios from 'axios';
import './SynthDashboardContainer.css'
import greenwoodLogo from '../../assets/images/greenwood_logo_white.png'
import LineChart from '../LineChartContainer/LineChartContainer';
import DoughnutChart from '../DoughnutChartContainer/DoughnutChartContainer';
import Web3 from 'web3';
import Fortmatic from 'fortmatic'
import config from './../../config/config'

class SynthDashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // accessToken: '',
            // refreshToken: '',
            hasSession: false,
            currentTab: 'Portfolio',
            // currentCompoundTab: 'Deposit',
            // userName: '',
            // userId: '',
            portfolioValue: '',
            portfolioYield: '',
            dailyValue: '',
            dailyYield: '',
            // accountIds: {},
            transactions: [],
            doughnutChartData: {},
            lineChartData: {},
            dashboard: false,
            compound: true,
            hasDaiBalance:false,
            paymentMethods: {},
            // buyAmount: '',
            buyButtonDisabled:false,
            // buyToken: 'DAI',
            // buyMethod: '',
            // daiAccountId: '',
            updatingDashboard: false,
            // compoundDepositAmount:'',
            // compoundWithdrawAmount:'',
            processingTransaction: false,
            // compoundBalance: '',
            authModalSignup: false,
            hasWeb3Provider: false,
            currentAccount:'',
            hasGreenwoodContracts: true,
            twitterLoaded: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        // this.handleCompoundTabChange = this.handleCompoundTabChange.bind(this);
        this.checkActiveTab = this.checkActiveTab.bind(this);
        // this.handleBuySubmit = this.handleBuySubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleCompoundDepositSubmit = this.handleCompoundDepositSubmit.bind(this)
        // this.handleCompoundWithdrawSubmit = this.handleCompoundWithdrawSubmit.bind(this)
        this.handleAuthModalChange = this.handleAuthModalChange.bind(this)
        this.handleFortmaticAuth = this.handleFortmaticAuth.bind(this);
        this.smartTrim = this.smartTrim.bind(this);
        this.handleMetamaskAuth = this.handleMetamaskAuth.bind(this);
        this.getTransactionsByAccount = this.getTransactionsByAccount.bind(this)
    }

    async componentDidMount() {
        console.log( 'PROVIDER: ', Web3.currentProvider )
        if ( Web3.currentProvider ) {
            Web3.currentProvider.enable().then(console.log)
        }
        window.twttr.ready(() => {
            window.twttr.widgets.load()
            this.setState({
                twitterLoaded: true
            });
        })
        // const fm = new Fortmatic('pk_test_A46769E03566AC61');
        // const web3 = new Web3(fm.getProvider());
        // web3.currentProvider.enable().then(console.log)

        // if (window.ethereum) {
        //     // Use MetaMask provider
        //     window.web3 = new Web3(window.ethereum);
        //     window.web3.currentProvider.enable().then(console.log)
        //   } else {
        //     // Use Fortmatic provider
        //     window.web3 = new Web3(fm.getProvider());
        //     window.web3.currentProvider.enable().then(console.log)
        //   }

        
        // if ( this.props.location.state && this.props.location.state.userName &&  this.props.location.state.userId) {
        //     this.setState({
        //         userName: this.props.location.state.userName,
        //         userId: this.props.location.state.userId,
        //         hasDaiBalance: this.props.location.state.hasDaiBalance,
        //         portfolioValue: this.props.location.state.portfolioValue,
        //         accountIds: {...this.props.location.state.accountIds},
        //         transactions: [...this.props.location.state.transactions],
        //         doughnutChartData: {...this.props.location.state.doughnutChartData},
        //         lineChartData: {...this.props.location.state.lineChartData},
        //         paymentMethods: {...this.props.location.state.paymentMethods},
        //         daiAccountId: this.props.location.state.daiAccountId,
        //         accessToken: this.props.location.state.accessToken,
        //         refreshToken: this.props.location.state.refreshToken
        //     })
        // }

        // if (this.state.userId) {
        //     console.log( 'FETCHING COMPOUND BALANCE', this.state.userId )
        //     try {
        //         const userData = await axios.post('http://localhost:5000/compound/balance', { coinbaseId: this.state.userId });
        //         console.log( 'TAB CHANGE DATA FOR COMPOUND BALANCE: ', userData.data.compoundBalance );
        //         this.setState({
        //             compoundBalance: userData.data.compoundBalance 
        //         });
        //     } catch ( err ) {
        //         console.log( `Error getting compound balance - ${err.message}` );
        //     }
        // }


    }

    checkActiveTab = (path) => {
        return path === this.state.currentTab;
    }

    checkActiveCompoundTab = (path) => {
        return path === this.state.currentCompoundTab;
    }

    checkWeb3Provider = () => {
        const provider = window.web3.isConnected();
        console.log( 'provider', provider )
        if ( provider ) {
            return true
        } else {
            return false
        }
    }

    handleTabChange = async (event) => {
        this.setState({
            twitterLoaded: false
        });
        event.persist()
        window.twttr.ready(() => {
            window.twttr.widgets.load()
            this.setState({
                twitterLoaded: true
            });
        })
        let newId = event.target.id;
        let oldId = this.state.currentTab

        console.log( 'TAB CHANGE EVENT: ', event )
        console.log( 'TAB CHANGE ID: ', newId )

        if (newId.includes( '-' ) ) {
            console.log( newId )
            const splitValue = newId.split('-');
            newId = splitValue[0]
        }

        this.setState({
            currentTab: newId
        });

        if (newId === 'Portfolio' ) {
            console.log( 'FETCH PORTFOLIO DATA' );
            // this.setState({
            //     updatingDashboard: true
            // })
        
            // const dashboardParams = {
            //     accessToken: this.state.accessToken,
            //     refreshToken: this.state.refreshToken
            // }

            // try {
            //     const userData = await axios.post('http://localhost:5000/setup', dashboardParams);
            //     console.log( 'TAB CHANGE DATA FOR DASHBOARD SETUP: ', userData );
            //     this.setState({
            //         transactions: userData.data.transactions 
            //         , doughnutChartData: userData.data.doughnutChartData
            //         , lineChartData: userData.data.lineChartData
            //         , updatingDashboard: false
            //     });
            // } catch ( err ) {
            //     console.log( `Error updating dashboard - ${err.message}` );
            // }

        } else if ( newId === 'Market' ) {
            console.log( 'FETCH MARKET DATA' );
            // try {
            //     console.log( 'FETCHING COMPOUND BALANCE', this.state.userId )
            //     const userData = await axios.post('http://localhost:5000/compound/balance', { coinbaseId: this.state.userId });
            //     console.log( 'TAB CHANGE DATA FOR COMPOUND BALANCE: ', userData.data.compoundBalance );
            //     this.setState({
            //         compoundBalance: userData.data.compoundBalance 
            //     });
            // } catch ( err ) {
            //     console.log( `Error getting compound balance - ${err.message}` );
            // }
        } else if ( newId === 'Create' ) {
            console.log( 'RENDER CREATE FORM' );
        }

    }

    // handleCompoundTabChange(event) {
    //     const newId = event.target.id;
    //     this.setState({
    //         currentCompoundTab: newId
    //     });
    // }

    handleAuthModalChange(event) {
        // const newId = event.target.id;
        this.setState({
            authModalSignup: !this.state.authModalSignup
        });
    }

    smartTrim(string, maxLength) {
        console.log( `called st with ${string} and ${maxLength}` );
        if (!string) return string;
        if (maxLength < 1) return string;
        if (string.length <= maxLength) return string;
        if (maxLength == 1) return string.substring(0,1) + '...';
    
        var midpoint = Math.ceil(string.length / 2);
        var toremove = string.length - maxLength;
        var lstrip = Math.ceil(toremove/2);
        var rstrip = toremove - lstrip;
        return string.substring(0, midpoint-lstrip) + '...' 
        + string.substring(midpoint+rstrip);
    } 

    async handleFortmaticAuth( event ) {
        const fm = new Fortmatic('pk_test_A46769E03566AC61');
        window.web3 = new Web3(fm.getProvider());
        let result = await window.web3.currentProvider.enable();
        result = await this.smartTrim(result[0], 16);
    
        this.setState({
            hasWeb3Provider: true,
            currentAccount: result
        })

    }

    async getTransactionsByAccount(myaccount, startBlockNumber, endBlockNumber) {
        if (endBlockNumber == null) {
          endBlockNumber = await window.web3.eth.getBlockNumber();
          console.log("Using endBlockNumber: " + endBlockNumber);
        }
        if (startBlockNumber == null) {
          startBlockNumber = endBlockNumber - 1000;
          console.log("Using startBlockNumber: " + startBlockNumber);
        }
        console.log("Searching for transactions to/from account \"" + myaccount + "\" within blocks "  + startBlockNumber + " and " + endBlockNumber);
      
        for (var i = startBlockNumber; i <= endBlockNumber; i++) {
          if (i % 1000 === 0) {
            console.log("Searching block " + i);
          }
          var block = await window.web3.eth.getBlock(i, true);
          console.log( 'GOT BLOCK: ', block );
          if (block != null && block.transactions != null) {
            block.transactions.forEach( function(e) {
              if (myaccount === "*" || myaccount === e.from || myaccount === e.to) {
                console.log("  tx hash          : " + e.hash + "\n"
                  + "   nonce           : " + e.nonce + "\n"
                  + "   blockHash       : " + e.blockHash + "\n"
                  + "   blockNumber     : " + e.blockNumber + "\n"
                  + "   transactionIndex: " + e.transactionIndex + "\n"
                  + "   from            : " + e.from + "\n" 
                  + "   to              : " + e.to + "\n"
                  + "   value           : " + e.value + "\n"
                  + "   time            : " + block.timestamp + " " + new Date(block.timestamp * 1000).toGMTString() + "\n"
                  + "   gasPrice        : " + e.gasPrice + "\n"
                  + "   gas             : " + e.gas + "\n"
                  + "   input           : " + e.input);
              }
            })
          }
        }
      }


    async handleMetamaskAuth( event ) {
        let result, resultTrimmed;
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);

            result = await window.web3.currentProvider.enable();
            resultTrimmed = await this.smartTrim(result[0], 18);
            const transactionHistory = await this.getTransactionsByAccount( result[0] )

          } else {
                alert('Metamask not detected.')
          }
    
        this.setState({
            hasWeb3Provider: true,
            currentAccount: resultTrimmed
        })

    }

    handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    // async handleBuySubmit(e) {
    //     e.preventDefault();
    //     if (this.state.buyButtonDisabled) {
    //         return;
    //     }

    //     this.setState({buyButtonDisabled: true});

    //     if (this.state.buyAmount === '') {
    //         console.log( 'BUY AMOUNT CANNOT BE EMPTY' )
    //         this.setState({ buyButtonDisabled: false })
    //         return;
    //     }

    //     if (this.state.buyMethod === '') {
    //         console.log( 'BUY METHOD CANNOT BE EMPTY' )
    //         this.setState({ buyButtonDisabled: false })
    //         return;
    //     }

    //     const buyData = {
    //         amount: this.state.buyAmount,
    //         paymentMethod: this.state.buyMethod,
    //         daiAccount: this.state.daiAccountId,
    //         coinbaseId: this.state.userId
    //     }

    //     this.setState({ processingTransaction: true });

    //     try {
    //         console.log( 'BUY DATA: ', buyData );
    //         let result = await axios.post('http://localhost:5000/buy', buyData);
    //         if ( result.status === 200 ) {
    //             // console.log('BUY RESPONSE:, ', result.data);
    //             this.setState({ buyButtonDisabled: false });
    //             this.setState({ processingTransaction: false });
    //             this.setState({ hasDaiBalance: true })
    //         }
    //     }
    //     catch (err) {
    //         console.log(`ERROR SUBMITTING BUY REQUEST - ${ err }`)
    //         this.setState({ buyButtonDisabled: false });
    //         this.setState({ processingTransaction: false })
    //         document.getElementById("buy-dai-form").reset();
    //     }
        
    // }

    // async handleCompoundDepositSubmit(e) {
    //     e.preventDefault();
    //     if (this.state.buyButtonDisabled) {
    //         return;
    //     }

    //     this.setState({ buyButtonDisabled: true });

    //     if ( this.state.compoundDepositAmount === '' ) {
    //         console.log( 'Deposit amount cannot be empty' )
    //         this.setState({ buyButtonDisabled: false })
    //         return;
    //     }

    //     this.setState({processingTransaction: true})

    //     const depositData = {
    //         amount: this.state.compoundDepositAmount,
    //         coinbaseId: this.state.userId
    //     }

    //     try {
    //         // console.log( 'COMPOUND DEPOSIT DATA: ', depositData );
    //         let result = await axios.post('http://localhost:5000/deposit', depositData);
    //         if ( result.status === 200 ) {
    //             console.log('DEPOSIT RESPONSE:, ', result.data);
    //             this.setState({ processingTransaction: false })
    //             this.setState({ buyButtonDisabled: false })
    //             document.getElementById("compound-deposit-form").reset();
    //         }
    //     }
    //     catch (err) {
    //         console.log(`ERROR SUBMITTING COMPOUND DEPOSIT - ${ err }`);
    //         this.setState({processingTransaction: false})
    //         this.setState({ buyButtonDisabled: false })
    //         document.getElementById("compound-deposit-form").reset();
    //     }

    //     try {
    //         console.log( 'FETCHING COMPOUND BALANCE', this.state.userId )
    //         const userData = await axios.post('http://localhost:5000/compound/balance', { coinbaseId: this.state.userId });
    //         console.log( 'TAB CHANGE DATA FOR COMPOUND BALANCE: ', userData.data.compoundBalance );
    //         this.setState({
    //             compoundBalance: userData.data.compoundBalance 
    //         });
    //     } catch ( err ) {
    //         console.log( `Error getting compound balance - ${err.message}` );
    //     }
    // }


    // async handleCompoundWithdrawSubmit(e) {
    //     e.preventDefault();
    //     if (this.state.buyButtonDisabled) {
    //         return;
    //     }

    //     this.setState({buyButtonDisabled: true});

    //     if (this.state.compoundWithdrawAmount === '') {
    //         console.log( 'Withdraw amount cannot be empty' )
    //         this.setState({ buyButtonDisabled: false })
    //         return;
    //     }

    //     this.setState({processingTransaction: true})

    //     const withdrawData = {
    //         amount: this.state.compoundWithdrawAmount,
    //         coinbaseId: this.state.userId,
    //         daiAccountId: this.state.daiAccountId
    //     }

    //     try {
    //         // console.log( 'COMPOUND DEPOSIT DATA: ', depositData );
    //         let result = await axios.post('http://localhost:5000/withdraw', withdrawData);
    //         if ( result.status === 200 ) {
    //             // console.log('WITHDRAW RESPONSE:, ', result.data);
    //             this.setState({ processingTransaction: false })
    //             this.setState({ buyButtonDisabled: false })
    //             document.getElementById("compound-deposit-form").reset();
    //             this.setState({compoundBalance: result.data.compoundBalance})
    //         }
    //     }
    //     catch (err) {
    //         console.log(err)
    //         this.setState({processingTransaction: false})
    //         document.getElementById("compound-deposit-form").reset();
    //     }

    //     try {
    //         console.log( 'FETCHING COMPOUND BALANCE', this.state.userId )
    //         const userData = await axios.post('http://localhost:5000/compound/balance', { coinbaseId: this.state.userId });
    //         console.log( 'TAB CHANGE DATA FOR COMPOUND BALANCE: ', userData.data.compoundBalance  );
    //         this.setState({
    //             compoundBalance: userData.data.compoundBalance 
    //         });
    //     } catch ( err ) {
    //         console.log( `Error getting compound balance - ${err.message}` );
    //     }
    // }

    render() {
    // console.log( this.state )
    // const coinbaseAuthUrl = process.env.COINBASE_AUTH_URL || config.coinbaseAuthUrl;

    return (
        <div>
            { this.state.updatingDashboard === false ? 
            <div className="admin">
                {this.state.currentTab && this.state.currentTab !== '' ? 
                    <nav className="admin__nav">
                        <a href="/" className="logo">
                            <img src={greenwoodLogo} alt="Greenwood" width="180" height="28"/>
                        </a>

                        <ul style={{textAlign: 'center'}}>
                        <p className="logged-in-as"style={{marginBottom:"17%", color:"#FFFFFF"}}><Badge style={{color: '#66676F' }}className={`${ this.state.currentAccount ? 'web3Connected' : 'web3NotConnected' }`}>{this.state.currentAccount ? this.state.currentAccount : 'No wallet connected'}</Badge></p>                
                        </ul>
                        
                        <ul className="menu">
                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Portfolio') ? 'active' : '' }`} id="Portfolio" onClick={this.handleTabChange}>Portfolio overview</a>  
                            </li>

                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Create') ? 'active' : '' }`} id="Create" onClick={this.handleTabChange}>Contract builder</a>
                            </li>

                            <li className="menu__item">
                                <a className={`menu__link ${ this.checkActiveTab('Market') ? 'active' : '' }`} id="Market" onClick={this.handleTabChange}>Trading desk</a>
                            </li>

                        </ul>
                    </nav>
            
                :
                    null 
                }
            
                {this.state.currentTab === 'Portfolio' &&  
                    <main className="admin__main">
                        { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === false && 
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                                    <Form id="buy-dai-form" style={{width: "100%"}}>
                                        <div style={{textAlign: "center"}}>
                                            <h3 className="graphik-bold color-orange mobile-responsive-card-text" style={{marginBottom:"7%"}} >Greenwood is in<br/>closed Alpha</h3>
                                            <div className="separator mobile-seperator"></div>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%"}}>If you are an alpha tester please use your account to access your Greenwood Terminal.</h5>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%", marginBottom:"7%"}}>Otherwise, you can request access to Greenwood<br/>via Twitter</h5>
                                            {this.state.twitterLoaded && <a class="twitter-follow-button" href="https://twitter.com/GreenwoodDeFi" data-size="large"> Follow @GreenwoodDeFi</a>}
                                        </div>
                                        {/* <div style={{color:'white'}}>
                                            <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                            <div className="pull-right">
                                                    {this.state.authModalSignup === true &&
                                                        <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                                    }

                                                    {
                                                        this.state.authModalSignup === false &&
                                                        <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                                    }
                                            </div>
                                        </div>

                                        <hr style={{backgroundColor:'#2D2F3A'}}/>

                                        <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                                <div>
                                                    {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                                </div>
                                                <div style={{fontSize: '0.6em'}}>
                                                    Powered by Fortmatic
                                                </div>
                                            </Button>
                                        </FormGroup>

                                        <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                        <div className="">
                                            <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                        </div>

                                        <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                                <div>
                                                    MetaMask
                                                </div>
                                            </Button>
                                        </FormGroup> */}

                                    </Form>
                                </div>
                            </div>
                        }

                        { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === true && this.state.hasGreenwoodContracts === false &&
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>

                                        {/* Welcome to Greenwood! Unlock your Portfolio by buying, selling, or creating a contract! */}
                                        <span className="graphik-bold" style={{fontSize:'1em', textAlign:'center', color:'white'}}>Welcome to Greenwood</span>
                                        <hr style={{backgroundColor:'#2D2F3A'}}/>
                                        <span style={{color:'#FFFFFF', textAlign:'center', fontSize:'1.3rem'}}>Unlock your portfolio by:</span>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-buy">
                                                        Buying a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}>
                                                <Button className="fortmatic-form-button lift" type="button">
                                                    <div id="Market-welcome-sell">
                                                        Selling a contract
                                                    </div>
                                                </Button>
                                            </a>
                                        </div>

                                        <div style={{textAlign: "center", marginTop: '5%'}}>
                                            {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                            <a onClick={this.handleTabChange}><Button className="fortmatic-form-button lift" type="button">
                                                <div id="Create-welcome">
                                                    Creating a contract
                                                </div>
                                            </Button></a>
                                        </div>


                                </div>
                            </div>
                        }

                        <div style={{height: "55px"}}>
                            <h4 className="admin-header" style={{verticalAlign: "middle"}}>Portfolio Overview</h4>
                            <div class="separator"></div>
                        </div>

                        <div className="dashboard">

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Portfolio value</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioValue ? this.state.portfolioValue : '--' }
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Portfolio yield</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 hr change</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.dailyValue ? this.state.dailyValue : '--'}
                                </div>
                            </div>

                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 hr yield</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.dailyYield ? this.state.dailyYield : '--'}
                                </div>
                            </div>


                            <div className="dashboard__item--chart">
                                <h5 className="dashboard-item-header">Portfolio value</h5>
                                <div className="card gw-card-dark-flat">
                                    { this.state.lineChartData ? <LineChart data={this.state.lineChartData} /> : null }
                                </div>
                            </div>

                            <div className="dashboard__item--chart">
                                <h5 className="dashboard-item-header">Portfolio composition</h5>
                                <div className="card gw-card-dark-flat ">
                                    { this.state.doughnutChartData.labels && this.state.doughnutChartData.datasets ? <DoughnutChart data={this.state.doughnutChartData}/> : null }
                                </div>
                            </div>

                            <div className="dashboard__item dashboard__item--full">
                                <h5 className="dashboard-item-header">Current contracts</h5>
                                <div className="card gw-card-dark-flat">
                                    <Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Size</th>
                                                <th>Token</th>
                                                <th>Native amount</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.transactions.map(function(item, key) {
                                            return (
                                                <tr key = {key}>
                                                    <td>{item.type}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{item.nativeAmount}</td>
                                                    <td>{item.timestamp}</td>
                                                </tr>
                                            )
                                        })}</tbody>
                                    </Table>
                                </div>
                            </div>


                            <div className="dashboard__item dashboard__item--full">
                                <h5 className="dashboard-item-header">Portfolio transaction history</h5>
                                <div className="card gw-card-dark-flat">
                                    <Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Size</th>
                                                <th>Token</th>
                                                <th>Native amount</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.transactions.map(function(item, key) {
                                            return (
                                                <tr key = {key}>
                                                    <td>{item.type}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{item.nativeAmount}</td>
                                                    <td>{item.timestamp}</td>
                                                </tr>
                                            )
                                        })}</tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </main> 
                }

                { this.state.currentTab === 'Market' &&
                    <main className="admin__main">
                        { this.state.currentTab === 'Market' && this.state.hasWeb3Provider === false && 
                            <div className="overlay">
                                <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                                    <Form id="buy-dai-form" style={{width: "100%"}}>
                                    <div style={{textAlign: "center"}}>
                                            <h3 className="graphik-bold color-orange mobile-responsive-card-text" style={{marginBottom:"7%"}} >Greenwood is in<br/>closed Alpha</h3>
                                            <div className="separator mobile-seperator"></div>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%"}}>If you are an alpha tester please use your account to access your Greenwood Terminal.</h5>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%", marginBottom:"7%"}}>Otherwise, you can request access to Greenwood<br/>via Twitter</h5>
                                            {this.state.twitterLoaded && <a class="twitter-follow-button" href="https://twitter.com/GreenwoodDeFi" data-size="large"> Follow @GreenwoodDeFi</a>}
                                    </div>
                                        {/* <div style={{color:'white'}}>
                                            <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                            <div className="pull-right">
                                                    {this.state.authModalSignup === true &&
                                                        <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                                    }

                                                    {
                                                        this.state.authModalSignup === false &&
                                                        <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                                    }
                                            </div>
                                        </div>

                                        <hr style={{backgroundColor:'#2D2F3A'}}/>

                                        <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                                <div>
                                                    {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                                </div>
                                                <div style={{fontSize: '0.6em'}}>
                                                    Powered by Fortmatic
                                                </div>
                                            </Button>
                                        </FormGroup>

                                        <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                        <div className="">
                                            <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                        </div>

                                        <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                            <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                                <div>
                                                    MetaMask
                                                </div>
                                            </Button>
                                        </FormGroup> */}
                                    </Form>
                                </div>
                            </div>
                        }

                        <div style={{height: "55px", }}>
                            <h4 className="admin-header">Trading desk</h4>
                            <div class="separator"></div>
                        </div>

                        <div className="dashboard">
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Value Locked</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.compoundBalance ? this.state.compoundBalance : '--' }
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">24 Hr % Change</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Long Allocation</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <h5 className="dashboard-item-header">Total Short Allocation</h5>
                                <div className="card dashboard-header-card">
                                    {this.state.portfolioYield ? this.state.portfolioYield : '--'}
                                </div>
                            </div>
                            <br/>
                            <div className="dashboard__item--full">
                                {/* <h5 className="dashboard-item-header">Manage</h5> */}
                                <div className="card form-card" style={{background:"transparent !important"}}>
                                <Nav tabs>
                                    <NavItem className="nav-item-50-percent">
                                        <NavLink className={`${ this.checkActiveCompoundTab('Deposit') ? 'active deposit-tab' : 'deposit-tab' }`} href="#" id="Deposit" onClick={this.handleCompoundTabChange}>Buy Offers</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-item-50-percent">
                                        <NavLink className={`${ this.checkActiveCompoundTab('Withdraw') ? 'active withdraw-tab' : 'withdraw-tab' }`} href="#" id="Withdraw" onClick={this.handleCompoundTabChange}>Sell Offers</NavLink>
                                    </NavItem>
                                </Nav>

                                <Table size="sm" className="greenwood-table" hover>
                                        <thead>
                                            <tr>
                                                <th>Asset</th>
                                                <th>Status</th>
                                                <th>Size</th>
                                                <th>Token</th>
                                                <th>Native amount</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody> {this.state.transactions.map(function(item, key) {
                                            return (
                                                <tr key = {key}>
                                                    <td>{item.type}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{item.nativeAmount}</td>
                                                    <td>{item.timestamp}</td>
                                                </tr>
                                            )
                                        })}</tbody>
                                </Table>

                                {/* <Form id="compound-deposit-form">
                                    <FormGroup style={{marginBottom: "0"}}>
                                        { this.state.processingTransaction && 
                                            <div className="Aligner deposit-loader">
                                                <div className="Aligner-item">
                                                    <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
                                                </div>
                                            </div> 
                                        }

                                        { this.checkActiveCompoundTab('Deposit') && !this.state.processingTransaction && 
                                            <Input type="number" name="compoundDepositAmount" id="compoundDepositAmount" placeholder="-" className="deposit-input" value={this.state.compoundDepositAmount} onChange={this.handleChange}/>
                                        }

                                        { this.checkActiveCompoundTab('Withdraw') && !this.state.processingTransaction && 
                                            <Input type="number" name="compoundWithdrawAmount" id="compoundWithdrawAmount" placeholder="-" className="deposit-input" value={this.state.compoundWithdrawAmount} onChange={this.handleChange}/>
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="compound-deposit-token-input" disabled/>
                                    </FormGroup>

                                    <FormGroup style={{textAlign: "center"}}>
                                        { this.checkActiveCompoundTab('Deposit') && 
                                            <Button className="compound-form-button lift" type="submit" onClick={this.handleCompoundDepositSubmit}>Submit</Button>
                                        }
                                        { this.checkActiveCompoundTab('Withdraw') && 
                                            <Button className="compound-form-button lift" type="submit" onClick={this.handleCompoundWithdrawSubmit}>Submit</Button>
                                        }
                                    </FormGroup>
                                </Form> */}
                                </div>
                            </div>
                        </div>
                    </main> 
                }



                { this.state.currentTab === 'Create' &&
                 <main className="admin__main">
                 { this.state.currentTab === 'Create' && this.state.hasWeb3Provider === false && 
                      <div className="overlay">
                          <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>
                              <Form id="buy-dai-form" style={{width: "100%"}}>
                                <div style={{textAlign: "center"}}>
                                            <h3 className="graphik-bold color-orange mobile-responsive-card-text " style={{marginBottom:"7%"}} >Greenwood is in<br/>closed Alpha</h3>
                                            <div className="separator mobile-seperator"></div>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%"}}>If you are an alpha tester please use your account to access your Greenwood Terminal.</h5>
                                            <h5 className="roboto" style={{color: "white", marginTop:"7%", marginBottom:"7%"}}>Otherwise, you can request access to Greenwood<br/>via Twitter</h5>
                                            {this.state.twitterLoaded && <a class="twitter-follow-button" href="https://twitter.com/GreenwoodDeFi" data-size="large"> Follow @GreenwoodDeFi</a>}
                                </div>
                                  {/* <h4 className="graphik-bold" style={{textAlign: "center", color: "white", marginTop:"2%"}}><span className='pull-left'>Get Started</span><span className='pull-right'></span></h4>
                                  <div style={{color:'white'}}>
                                      <span className="graphik-bold" style={{fontSize:'1em'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' }</span>
                                      <div className="pull-right">
                                              <span id='Login' style={{fontSize:'0.5em'}}>{this.authModalSignup ? 'Already have an account?' : 'Don\'t have an account?'} <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>{this.authModalSignup ? 'Log in' : 'Sign up'}</span></a></span>
                                              {this.state.authModalSignup === true &&
                                                  <span style={{fontSize:'0.5em'}}>Already have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Log in</span></a></span>
                                              }

                                              {
                                                  this.state.authModalSignup === false &&
                                                  <span style={{fontSize:'0.5em'}}>Don't have an account? <a onClick={this.handleAuthModalChange}><span style={{color: '#FF7957'}}>Sign up</span></a></span>
                                              }
                                      </div>
                                  </div>

                                  <hr style={{backgroundColor:'#2D2F3A'}}/>

                                  <FormGroup style={{textAlign: "center", marginTop: '5%'}}>
                                      <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/>
                                      <Button className="fortmatic-form-button lift"  onClick={this.handleFortmaticAuth} >
                                          <div>
                                              {this.state.authModalSignup ? 'Sign up' : 'Log in' }
                                          </div>
                                          <div style={{fontSize: '0.6em'}}>
                                              Powered by Fortmatic
                                          </div>
                                      </Button>
                                  </FormGroup>

                                  <div class="separator graphik-bold" style={{color:'#2D2F3A'}}><span style={{fontSize:'0.5em'}}>OR</span></div>

                                  <div className="">
                                      <span style={{fontSize:'0.5em', color:'white'}}>{this.state.authModalSignup ? 'Sign up' : 'Log in' } via</span>
                                  </div>

                                  <FormGroup style={{textAlign: "center", marginTop: '2%'}}>
                                      <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/>
                                      <Button className="fortmatic-form-button lift"  onClick={this.handleMetamaskAuth} >
                                          <div>
                                              MetaMask
                                          </div>
                                      </Button>
                                  </FormGroup> */}

                              </Form>
                          </div>
                      </div>
                  }

                  { this.state.currentTab === 'Portfolio' && this.state.hasWeb3Provider === true && this.state.hasGreenwoodContracts === false &&
                      <div className="overlay">
                          <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"70%", padding:'5%'}}>

                                  {/* Welcome to Greenwood! Unlock your Portfolio by buying, selling, or creating a contract! */}
                                  <span className="graphik-bold" style={{fontSize:'1em', textAlign:'center', color:'white'}}>Welcome to Greenwood</span>
                                  <hr style={{backgroundColor:'#2D2F3A'}}/>
                                  <span style={{color:'#FFFFFF', textAlign:'center', fontSize:'1.3rem'}}>Unlock your portfolio by:</span>

                                  <div style={{textAlign: "center", marginTop: '5%'}}>
                                      {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                      <a onClick={this.handleTabChange}>
                                          <Button className="fortmatic-form-button lift" type="button">
                                              <div id="Market-welcome-buy">
                                                  Buying a contract
                                              </div>
                                          </Button>
                                      </a>
                                  </div>

                                  <div style={{textAlign: "center", marginTop: '5%'}}>
                                      {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                      <a onClick={this.handleTabChange}>
                                          <Button className="fortmatic-form-button lift" type="button">
                                              <div id="Market-welcome-sell">
                                                  Selling a contract
                                              </div>
                                          </Button>
                                      </a>
                                  </div>

                                  <div style={{textAlign: "center", marginTop: '5%'}}>
                                      {/* <Input type="text" name="depositToken" id="depositToken" placeholder="Token: DAI"  value="Token: DAI" className="deposit-token-input" disabled/> */}
                                      <a onClick={this.handleTabChange}><Button className="fortmatic-form-button lift" type="button">
                                          <div id="Create-welcome">
                                              Creating a contract
                                          </div>
                                      </Button></a>
                                  </div>


                          </div>
                      </div>
                  }

                  <div style={{height: "55px"}}>
                      <h4 className="admin-header" style={{verticalAlign: "middle"}}>Contract Builder</h4>
                      <div class="separator"></div>
                  </div>

                  <div className="dashboard">
                      
                      <div className="dashboard__item--chart">
                      <h5 className="dashboard-item-header">Contract terms</h5>
                          <h5 className="dashboard-item-header"></h5>
                          <div className="card gw-card-dark-flat">
                          <Form>
                              <FormGroup row>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Position</Label>
                                  <Col sm={8}>
                                      <div>
                                          {/* working here */}
                                      <CustomInput className="create-form-radio-label" type="radio" id="exampleCustomInline" label="Buy" value="buyer" inline checked={this.state.selectedPosition === 'buyer'} onChange={this.changeRadio} />
                                      <CustomInput className="create-form-radio-label" type="radio" id="exampleCustomInline2" label="Sell" value="seller" inline checked={this.state.selectedPosition === 'seller'} onChange={this.changeRadio} />
                                      </div>
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Contract Type</Label>
                                  <Col sm={8}>
                                  <Input type="select" name="selectedContractType" id="selectedContractType" value={this.state.selectedContractType} onChange={this.handleChange}>
                                  <option value='' disabled defaultValue>Select a contract type</option>
                                  <option value='forward' name="forward">Greenwood Forward &trade;</option>
                                  </Input>
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Underlying asset</Label>
                                  <Col sm={8}>
                                  <Input type="select" name="selectedUnderlyingAsset" id="selectedUnderlyingAsset" value={this.state.selectedUnderlyingAsset} onChange={this.handleChange}>
                                  <option value='' disabled defaultValue>Select an underlying asset</option>
                                  <option name="WTI" value='WTI'>Oil (WTI)</option>
                                  </Input>
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Strike price</Label>
                                  <Col sm={8}>
                                      <Input type="number" name="selectedStrikePrice" id="selectedStrikePrice" placeholder="--" onChange={this.handleChange}/>  
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Size</Label>
                                  <Col sm={8}>
                                      <Input type="number" name="selectedSize" id="selectedSize" placeholder="--" onChange={this.handleChange}/>  
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Collateralizarion ratio</Label>
                                  <Col sm={8}>
                                      <Input type="number" name="selectedMinimumCollateralizationRatio" id="selectedMinimumCollateralizationRatio" placeholder="--" onChange={this.handleChange}/>  
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Update frequency</Label>
                                  <Col sm={8}>
                                  <Input type="select" name="selectedUpdateFrequency" id="selectedUpdateFrequency" value={this.state.selectedUpdateFrequency} onChange={this.handleChange}>
                                  <option value='' disabled defaultValue>Select the price update frequency</option>
                                  <option value="daily" name="daily">Daily</option>
                                  </Input>
                                  </Col>
                              </FormGroup>

                              <div className="separator"></div>

                              <FormGroup row style={{marginTop: "1rem"}}>
                                  <Label for="exampleEmail" className="create-form-input-label" sm={4}>Duration</Label>
                                  <Col sm={8}>
                                  <Input type="select" name="selectedDuration" id="selectedDuration" value={this.state.selectedDuration} onChange={this.handleChange}>
                                  <option value='' disabled defaultValue>Select a duration for this contract</option>
                                  <option value="604800" name="selectedDuration">1 week</option>
                                  </Input>
                                  </Col>
                              </FormGroup>

                              

                              </Form>
                          </div>
                      </div>

                      <div className="dashboard__item--chart">
                          <h5 className="dashboard-item-header" style={{textAlign:"center"}}>Issuance cost</h5>
                          <div className="card gw-card-dark-flat-outline ">
                              <div className="">
                                  <Form>
                                      <FormGroup row>
                                          <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Collateral required</Label>
                                          <Col sm={8}>
                                              <Input type="text" name="initialCollateral" id="initialCollateral" value={this.state.initialCollateral ? `${this.state.initialCollateral}Đ` : '--'} disabled style={{textAlign:"center"}}/>  
                                          </Col>
                                      </FormGroup>
                              

                                      <div class="separator"></div>

                                      <FormGroup row style={{marginTop: "1rem"}}>
                                          <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Deployment gas</Label>
                                          <Col sm={8}>
                                              <Input type="text" name="size" id="size" value={this.state.initialCollateral ? "~3000000 wei" : "--"} disabled style={{textAlign:"center"}}/>  
                                          </Col>
                                      </FormGroup>

                                      <div class="separator"></div>

                                      <FormGroup row style={{marginTop: "1rem"}}>
                                          <Label for="exampleEmail" className="create-form-input-label color-white" sm={4}>Maintenence gas</Label>
                                          <Col sm={8}>
                                              <Input type="text" name="size" id="size" value={this.state.initialCollateral ? "~850000 wei" : "--"}disabled style={{textAlign:"center"}}/>  
                                          </Col>
                                      </FormGroup>

                                      <div class="separator"></div>

                                      <FormGroup row style={{marginTop: "1rem"}}>
                                          <Label for="exampleEmail" className="create-form-input-label" sm={4}>Total issuance cost</Label>
                                          <Col sm={8}>
                                              {this.state.totalCost && <h1 className="graphik-bold color-orange" style={{textAlign:"left"}}>${this.state.totalCost}</h1>}
                                          </Col>
                                      </FormGroup>
                                      <div style={{textAlign:"center"}}>
                                      <Button className="access-button roboto-regular" href="" style={{textAlign:"center"}} onClick={this.submitContractTerms}>Deploy contract to Ethereum</Button>
                                      </div>

                                      <div style={{height: "100%"}} className="Aligner">
                                          <div className="Aligner-item" style={{paddingTop:"10%", maxWidth: "100%"}}>
                                              { this.state.isDeployingContract && <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/> }
                                              { this.state.contractDeployed && this.state.deployedContractAddress && <h6>Contract deployed at address: {this.state.deployedContractAddress} <a href={`https://kovan.etherscan.io/address/${this.state.deployedContractAddress}`} target="_blank" rel="noopener noreferrer"><i class="fas fa-external-link-alt"></i></a></h6> }
                                              {/* <h6>Contract deployed at address: <a href="https://kovan.etherscan.io/address/0x642Aaa7B00D98A72f4833BbdF809298EE93C546D" target="_blank" rel="noopener noreferrer" style={{color:"white"}}>0x642Aaa7B00D98A72f4833BbdF809298EE93C546D <i class="fas fa-external-link-alt"></i></a></h6> */}
                                          </div>
                                      </div>

                                  </Form>
                              </div>
                          </div>
                      </div>

                  </div>
              </main> 
                }



            </div> 
            
            :
        
            // Div to show spinner when dashboard is updating
            <div style={{height: "100vh"}} className="Aligner oauth-aligner">
                <div className="Aligner-item">
                    <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
                </div>
            </div> 
        }
        </div>
    );
  }
}
export default withRouter(SynthDashboardContainer)