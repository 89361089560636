import React from 'react';
import { withRouter } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { Section } from 'react-bulma-components';
import axios from 'axios';
import './OauthContainer.css'
import greenwoodLogo from '../../assets/images/greenwood_logo.png'
const qs = require('querystring')


class OauthContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        accessToken: '',
        refreshToken: '',
        hasSession: false,
        currentTab: 'Portfolio'
    };
  }

  async componentDidMount(){
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authCode = params.get('code')
    const grantType = 'authorization_code'
    const clientId = 'aae8e7da013c52b042be9d9464c6d17e0e9c65be69c99e95e493142dcd735271'
    const clientSecret = 'c2b285c1fab619e1678f31ea9c46ff318cb6f6d37b486884a0e1f88df43c1eb6'
    const redirectUri = 'http://localhost:3000/oauth/coinbase'
    const tokenHost = 'https://api.coinbase.com/oauth/token'
    const backendUrl = 'http://localhost:5000'

    // URL ENCODED METHOD WITH PARAMS
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    let requestBody = qs.stringify({
        grant_type: grantType
        , code: authCode
        , client_id: clientId
        , client_secret: clientSecret
    })

    requestBody += '&redirect_uri='+redirectUri;

    try {
        const result = await axios.post(`${tokenHost}`, requestBody, config)
        if ( result && result.data ) {
            this.setState({
                accessToken: result.data.access_token,
                refreshToken: result.data.refresh_token,
                hasSession: true
            })

            const dashboardParams = {
              accessToken: result.data.access_token,
              refreshToken: result.data.refresh_token
            }

          const userData = await axios.post('http://localhost:5000/setup', dashboardParams);

          if ( this.state.hasSession === true ) {
              this.props.history.push({
                  pathname: '/dashboard',
                  state: {
                    "userName": userData.data.name
                    , "userId": userData.data.id 
                    , "portfolioValue": userData.data.portfolioValue 
                    , "accountIds": userData.data.accountIds
                    , "transactions": userData.data.transactions 
                    , "doughnutChartData": userData.data.doughnutChartData
                    , "lineChartData": userData.data.lineChartData
                    , "paymentMethods": userData.data.paymentMethods 
                    , "hasDaiBalance": userData.data.hasDaiBalance 
                    , "daiAccountId": userData.data.daiAccountId 
                    , "accessToken": result.data.access_token
                    , "refreshToken": result.data.refresh_token
                  }
                })
          }
        
        }
    } catch ( err ) {
        console.log( 'ERROR: ', err )
    }
  }

    render() {
    return (
      <div style={{height: "100vh"}} className="Aligner oauth-aligner">
          <div className="Aligner-item">
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="light"/>
          </div>
      </div>
    );
  }
}
export default withRouter(OauthContainer)