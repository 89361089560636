import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';

// const data = {
//   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//   datasets: [
//     {
//       label: 'Portfolio value (USD)',
//       fill: true,
//       lineTension: 0.2,

//       borderColor: 'rgb(255,121,87)',
//       borderCapStyle: 'butt',
//       borderDash: [],
//       borderDashOffset: 0.0,
//       borderJoinStyle: 'miter',
//       pointBorderColor: 'rgb(255,121,87)',
//       pointBackgroundColor: 'rgb(255,121,87)',
//       pointBorderWidth: 4,
//       pointHoverRadius: 8,
//       pointHoverBackgroundColor: 'rgb(255,121,87)',
//       pointHoverBorderColor: 'rgb(255,121,87)',
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//       data: [65, 59, 80, 81, 56, 55, 40, 5, 45, 20, 90, 50]
//     }
//   ]
// };

const options = {
legend: {
    position: 'bottom',
        labels: {
            boxHeight: 1
        }
    },
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
  }
};

export default class LineChartContainer extends Component {
 
  constructor(props) {
    super(props);    
    this.state = {

    }
  }
  
  render() {



    const data = (canvas) => {
        const ctx = canvas.getContext("2d")
        var gradient = ctx.createLinearGradient(0, 0, 0, 225);
        gradient.addColorStop(0, 'rgba(102,103,111,1)');   
        gradient.addColorStop(0.5, 'rgba(102,103,111,0.5)');   
        gradient.addColorStop(1, 'rgba(102,103,111,0)');
        const dataKeys = Object.values(this.props.data)
        // console.log( String (dataKeys) )
        let borderColor, pointBorderColor, pointBackgroundColor;
        if ( String(dataKeys) === '0,0,0,0,0,0,0,0,0,0,0,0' ) {
          borderColor = 'rgba(102,103,111,1)'
          pointBorderColor = 'rgba(102,103,111,1)'
          pointBackgroundColor = 'rgba(102,103,111,1)'
        } else {
          borderColor = 'rgb(255,121,87)'
          pointBorderColor = 'rgb(255,121,87)'
          pointBackgroundColor = 'rgb(255,121,87)'
          // borderColor = 'rgb(255,255,255)'
        }

        return {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                  {
                    label: 'Portfolio value (USD)',
                    fill: true,
                    lineTension: 0.4,
                    // backgroundColor: gradient,
                    backgroundColor: 'rgba(102,103,111,0.3)',
                    borderColor: borderColor,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: pointBorderColor,
                    pointBackgroundColor: pointBackgroundColor,
                    pointBorderWidth: 4,
                    pointHoverRadius: 8,
                    pointHoverBackgroundColor: 'rgb(255,121,87)',
                    pointHoverBorderColor: 'rgb(255,121,87)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: dataKeys ? dataKeys : [65, 59, 80, 81, 56, 55, 40, 5, 45, 20, 90, 50]
                    // data: [65, 59, 80, 81, 56, 55, 40, 5, 45, 20, 90, 50]
                  }
                ]
              }
        }
    return (
      <div>
        {/* <h2>Line Example</h2> */}
        { this.props.data ? <Line data={data} options={options}/> : null }
        {/* <Line ref="chart" data={data} options={options} /> */}
      </div>
    );
  }

  componentDidMount() {
    // const { datasets } = this.refs.chart.chartInstance.data
    // console.log(datasets[0].data);
  }
}