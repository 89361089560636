import React from 'react';
import { Navbar, Section, Container, Heading } from 'react-bulma-components';
import greenwoodLogo from '../../assets/images/greenwood_logo_white.png'
import './DemoContainer.css'
import ReactEmbedGist from 'react-embed-gist';



export default class DemoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <div>
          <div className="" style={{height: "100vh"}}>
            <div className="container">
                <Navbar
                style={{background: "transparent", paddingLeft:"0px"}}
                >
                    <Navbar.Brand style={{ marginLeft: "0px", marginTop:"1%" }}>
                        <Navbar.Item renderAs="a" href="/" style={{paddingLeft: "0px"}}>
                        <img
                            src={greenwoodLogo}
                            alt="Greenwood"
                            width="180"
                            height="28"
                        />
                        </Navbar.Item>
                        {/* <Navbar.Burger
                        active={this.state.isOpen}
                        onClick={this.toggle}
                        /> */}
                    </Navbar.Brand>
                    <Navbar.Menu active={this.state.isOpen}>
                        {/* <Navbar.Container position="end">
                        <Navbar.Item href="#">Login</Navbar.Item>
                        </Navbar.Container> */}
                    </Navbar.Menu>
                </Navbar>
              </div>
              <Section size="medium" style={{paddingTop: "4em"}}>
                <Container style={{textAlign: "center"}}>
                  <h2 className="color-orange graphik-bold">This is the Ethereum code for our Forward contract</h2>
                  <h5 className="color-white">We're writing backend integrations and a web interface to make this financial contract reliable and usable</h5>
                  <ReactEmbedGist gist="branmcf/066a2c08f4a59ef5d2fa25d1c5b55404"/>
                </Container>
              </Section>
        </div>
      </div>
    );
  }
}