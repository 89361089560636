import React from 'react';
import LoginContainer from "../../components/LoginContainer/LoginContainer.jsx"
import './LoginView.css';

export default class LoginView extends React.Component {  

    render() {
        return (
            <div>
                <LoginContainer></LoginContainer>
            </div>
        )
    } 
}