import React from 'react';
import OauthContainer from "../../components/OauthContainer/OauthContainer.jsx"
import './OauthView.css';

export default class OauthView extends React.Component {  

    render() {
        return (
            <div>
                <OauthContainer></OauthContainer>
            </div>
        )
    } 
}