import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';

// const data = {
// 	labels: [
// 		'USD',
// 		'BTC',
// 		'DAI'
// 	],
// 	datasets: [
//         {
//             data: [300, 50, 100],
//             backgroundColor: [
//                 '#FF7957',
//                 '#FF9378',
//                 '#FFAE9A'
//             ],
//             hoverBackgroundColor: [
//                 '#FF7957',
//                 '#FF9378',
//                 '#FFAE9A'
//             ]
//         }
//     ]
// };

const options = {
  legend: {
    position: 'left',
        labels: {
            boxHeight: 1
            , fontColor: "#2D2F3A"
            , borderWidth: 0
        }
    }
    ,elements: {
    arc: {
        borderWidth: 0,
        borderColor: "#2D2F3A"
    }
  }
  , tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        // var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
        //   return previousValue + currentValue;
        // });
        var currentValue = dataset.data[tooltipItem.index];        
        return currentValue.toFixed(2) + "%";
      }
    }
  }
};

export default class DoughnutChartContainer extends Component {
    constructor(props) {

      console.log( 'PROPS ', props)
        super(props);    
        this.state = {

        }
      }

  render() {
    return (
      <div>
        {/* <h2>Doughnut Example</h2> */}
        <Doughnut ref="chart" data={this.props.data} options={options} />
      </div>
    );
  }

  componentDidMount() {
    // const { datasets } = this.refs.chart.chartInstance.data
    // console.log(datasets[0].data);
  }
};

