import React from 'react';
import DemoVideoContainer from "../../components/DemoVideoContainer/DemoVideoContainer.jsx"
import './DemoVideoView.css';

export default class DemoVideoView extends React.Component {  

    render() {
        return (
            <div>
                <DemoVideoContainer></DemoVideoContainer>
            </div>
        )
    } 
}