import React from 'react';
import {
  Row,
  Col,
  Form
 } from 'reactstrap';
 import './MobileContainer.css'
//  const bgImg = require('../../assets/images/sammich.png');


export default class MobileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  async componentDidMount() {
    window.twttr.ready(() => {
        window.twttr.widgets.load()
        this.setState({
            twitterLoaded: true
        });
    })
}

  render() {
    return (
        <div className="card modal-card aligner" style={{background: "white", position: "absolute", left: "50%", top:"50%", transform: "translateX(-50%) translateY(-50%)", maxHeight:"100vh", padding:'0%', margin:"0"}}>
            <Form id="buy-dai-form" style={{width: "100%"}}>
                <div style={{textAlign: "center"}}>
                  <h3 className="graphik-bold color-orange mobile-responsive-card-text" style={{marginBottom:"7%"}} >Greenwood is in<br/>closed Alpha</h3>
                  <div className="separator mobile-seperator"></div>
                  <h5 className="roboto" style={{color: "white", marginTop:"7%", paddingLeft:"10%", paddingRight:"10%"}}>If you are an alpha tester please use your account to access your Greenwood Terminal.</h5>
                  <h5 className="roboto" style={{color: "white", marginTop:"7%", marginBottom:"7%", paddingLeft:"10%", paddingRight:"10%"}}>Otherwise, you can request access to Greenwood<br/>via Twitter</h5>
                  {this.state.twitterLoaded && <a class="twitter-follow-button" href="https://twitter.com/GreenwoodDeFi" data-size="large"> Follow @GreenwoodDeFi</a>}
                </div>
            </Form>
        </div>
    );
  }
}